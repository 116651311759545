import React from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { checkMarked, DeleteClose, refund,star } from "./assets";
import './../assets/css/common.css'
import { menuLightMode, menuDarkMode, downloadsActiveBlue } from "./assets";

// Customizable Area Start
export default function SimpleMenu(props: any) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className="btn"
            >
                {props.mode ? <img src={menuLightMode} className="mybook-setting-icon-light"  /> :
                    <img src={menuDarkMode} className="mybook-setting-icon" />}
            </button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={`bookmenu ${props.mode && "bookMenuDark"}`}
            >
                <MenuItem onClick={handleClose}> <img src={downloadsActiveBlue} /> Download</MenuItem>
                <MenuItem onClick={handleClose}> <img src={checkMarked} /> Mark as Read</MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        props.handleReadSetModalClose();
                    }}
                >
                    <img src={refund} /> Return Book
                </MenuItem>
                <MenuItem onClick={handleClose}> <img src={DeleteClose} /> Delete</MenuItem>
                {/* <MenuItem onClick={handleClose}> <img src={star} /> Rate this Book</MenuItem> */}
            </Menu>
        </>
    );
}
// Customizable Area End
