import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { RouteComponentProps, withRouter } from "react-router";
import { RouterProps } from "react-router";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { DialogProps } from "../../../components/src/DialogContext";

import { runEngine } from "../../../framework/src/RunEngine";
import * as Yup from "yup";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assetss";
// Customizable Area End
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const configJSON = require("./config");

export type Props =
    // RouterProps &
    RouteComponentProps &
    DialogProps & { navigation: any; classes: any } & withAlertBoxProps &
    withToastProps &
    withLoaderProps & {
        id: string;
        // Customizable Area Start
        // Customizable Area End
    };

interface S {
    // Customizable Area Start
    password: string;
    email: string;
    enablePasswordField: boolean;
    checkedRememberMe: boolean;
    placeHolderEmail: string;
    placeHolderPassword: string;
    imgPasswordVisible: any;
    imgPasswordInVisible: any;
    labelHeader: string;
    btnTxtLogin: string;
    labelRememberMe: string;
    btnTxtSocialLogin: string;
    labelOr: string;
    validationSchema: any;
    setOpen: any;
    open: boolean;
    loader: any;
    isLoginScreen: any;
    allCurrency: any;
    symbol: any;

    currencyOpen: boolean;
    selectedCountryError: boolean;
    selectedCountry: any;
    otp: any;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    classes: any;
    // Customizable Area End
}

export default class EmailAccountLoginController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiEmailLoginCallId: string = "";
    validationApiCallId: string = "";
    forgotPassword: string = "";
    emailReg: RegExp;
    labelTitle: string = "";
    getCountryCurrencyAPICallId: string = "";
    updateCountryApiCallId: string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.AlertMessage),
        ];
        let validationSchema = {
            email: Yup.string().required(configJSON.emailIsRequired),
            password: Yup.string().required(configJSON.pleaseEnterAPassword),
        };
        this.state = {
            email: "",
            password: "",
            enablePasswordField: true,
            checkedRememberMe: false,
            placeHolderEmail: configJSON.placeHolderEmail,
            placeHolderPassword: configJSON.placeHolderPassword,
            imgPasswordVisible: configJSON.imgPasswordVisible,
            imgPasswordInVisible: imgPasswordInVisible,
            labelHeader: configJSON.labelHeader,
            btnTxtLogin: configJSON.btnTxtLogin,
            labelRememberMe: configJSON.labelRememberMe,
            btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
            labelOr: configJSON.labelOr,
            validationSchema: validationSchema,
            setOpen: false,
            open: false,
            loader: false,
            isLoginScreen: false,

            allCurrency: [],
            symbol: "",
            currencyOpen: false,
            selectedCountry: null,
            selectedCountryError: false,
            otp: '',
        };

        this.emailReg = new RegExp("");
        this.labelTitle = configJSON.labelTitle;
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    //   goToEmailAccountRegistration = () => {
    // // console.log("Hello")
    //     const msg: Message = new Message(
    //       getName(MessageEnum.NavigateEmailSignUpMessage)
    //     );
    //     msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    //     msg.addData(getName(MessageEnum.NavigateEmailSignUpMessage), "email");
    //     // msg.addData("Data_key",{
    //     //   name:"Yashwanth",
    //     //   age:23

    //     // })

    //     this.send(msg);
    //   }

    async componentDidMount() {
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        // Customizable Area Start
        let authToken;
        let currencyCode;
        authToken = await StorageProvider.get("authToken");
        currencyCode = await StorageProvider.get("currencyCode");
        // await StorageProvider.remove("authToken");
        if (authToken && currencyCode != null) {
            return this.props.history.push("/Catalogue/Home");
        }
        authToken = await StorageProvider.get("publisherToken");
        if (authToken) {
            return this.props.history.push("/publisher");
        }
        authToken = await StorageProvider.get("adminToken");
        if (authToken) {
            return this.props.history.push("/AdminConsole");
        }
        // Customizable Area End
    }
    goTo = () => {
    };
    handleCloseLoader = () => {
        this.setState({
            open: false,
        });
    };
    handleTogle = (e: any) => {
        this.setState({
            open: !this.state.open,
        });
    };

    goToEmailAccountRegistration = () => {
        this.props.history.push("/EmailAccountRegistration");

        // const msg: Message = new Message(
        //   getName(MessageEnum.NavigationForgotPasswordMessage)
        // );
        // msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        // msg.addData(getName(MessageEnum.NavigateEmailSignUpMessage), "email");
        // this.send(msg);
    };

    // Customizable Area Start
    btnSocialLoginProps = {
        onPress: () => this.goToSocialLogin(),
    };


    // btnEmailLogInProps = {
    //   color: "#6200EE",
    //   onPress: () => this.doEmailLogIn()
    // };

    btnPasswordShowHideProps = {
        onPress: () => {
            this.setState({ enablePasswordField: !this.state.enablePasswordField });
            this.txtInputPasswordProps.secureTextEntry = !this.state
                .enablePasswordField;
            this.btnPasswordShowHideImageProps.source = this.txtInputPasswordProps
                .secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible;
        },
    };

    CustomCheckBoxProps = {
        onChangeValue: (value: boolean) => {
            this.setState({ checkedRememberMe: value });
            this.CustomCheckBoxProps.isChecked = value;
        },
        isChecked: false,
    };

    btnForgotPasswordProps = {
        onPress: () => this.goToForgotPassword(),
    };
    btnRegistrationProps = {
        onPress: () => this.goToRegistration(),
    };

    txtInputPasswordProps = {
        onChangeText: (text: string) => {
            this.setState({ password: text });

            //@ts-ignore
            this.txtInputPasswordProps.value = text;
        },
        secureTextEntry: true,
    };

    btnPasswordShowHideImageProps = {
        source: imgPasswordVisible,
    };

    btnRememberMeProps = {
        onPress: () => {
            this.setState({
                checkedRememberMe: !this.CustomCheckBoxProps.isChecked,
            });
            this.CustomCheckBoxProps.isChecked = !this.CustomCheckBoxProps.isChecked;
        },
    };

    txtInputEmailWebProps = {
        onChangeText: (text: string) => {
            this.setState({ email: text });

            //@ts-ignore
            this.txtInputEmailProps.value = text;
        },
    };

    txtInputEmailMobileProps = {
        ...this.txtInputEmailWebProps,
        autoCompleteType: "email",
        keyboardType: "email-address",
    };

    txtInputEmailProps = this.isPlatformWeb()
        ? this.txtInputEmailWebProps
        : this.txtInputEmailMobileProps;

    // Customizable Area End

    async receive(from: string, message: Message) {
        this.setState({
            open: false,
        });
        //console.log(message, "message");

        // Customizable Area Start
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            var errorResponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (apiRequestCallId === this.forgotPassword) {
                // console.log("forgotPassword@@@", responseJson.data);
                if (!responseJson.errors) {
                    // this.settingToken(responseJson.meta.token)
                    // localStorage.setItem("token", responseJson.meta.token);
                    toast.success(`OTP has been sent to ${this.state.email}`)

                    this.props.history.push("/ForgotPasswordOtp", { state: this.state.email, token: responseJson.meta.token })
                    // pathname: "/ForgotPasswordOtp",
                    // state: this.state.email,)

                }
                if (responseJson?.errors) {
                    toast.error(responseJson?.errors[0].otp)

                }
                this.setState({
                    setOpen: false,
                })
            }
            if (this.getCountryCurrencyAPICallId === apiRequestCallId) {
                if (responseJson?.data) {
                    this.setState({ allCurrency: responseJson.data, currencyOpen: true })
                    this.props.history.push("/Catalogue/Home");
                } else {
                    // toast.error("Something wrong");
                    this.props.history.push("/Catalogue/Home");
                }
            }

            if (apiRequestCallId === this.apiEmailLoginCallId) {

                this.props.hideLoader();
                console.log("@@@ res",responseJson);
                    this.saveLoggedInUserData(responseJson);
            }

            //Update Country
            if (apiRequestCallId === this.updateCountryApiCallId) {
                if (responseJson && responseJson?.data) {
                    await StorageProvider.set("currencyCode", responseJson?.data?.currency_code);
                    if (responseJson?.data?.currency_code == "USD") {
                        localStorage.setItem("symbol", "$");
                        localStorage.setItem("currency_code", "USD");
                    } else if (responseJson?.data?.currency_code == "INR") {
                        localStorage.setItem("symbol", "₹");
                        localStorage.setItem("currency_code", "INR");
                    } else if (responseJson?.data?.currency_code == "CAD") {
                        localStorage.setItem("symbol", "CA$");
                        localStorage.setItem("currency_code", "CAD");
                    } else if (responseJson?.data?.currency_code == "GBP") {
                        localStorage.setItem("symbol", "£");
                        localStorage.setItem("currency_code", "GBP");
                    }
                    toast.success(responseJson?.success);
                    this.props.history.push("/Catalogue/Home");
                }
                this.setState({ currencyOpen: false })
            }
        }
    }

    async saveLoggedInUserData(responseJson: any) {
        console.log("@@@", responseJson);

        if (responseJson && responseJson.meta && responseJson.meta.token) {
            const profileData = responseJson.user.data;
            const token = responseJson.meta.token;
            console.log("@@@ profile", responseJson.user.data);
            if (await StorageProvider.get("authToken")) {
                await StorageProvider.remove("authToken");
            }
            if (await StorageProvider.get("adminToken")) {
                await StorageProvider.remove("adminToken");
            }
            if (await StorageProvider.get("publisherToken")) {
                await StorageProvider.remove("publisherToken");
            }
            if (await StorageProvider.get("accountInfo")) {
                await StorageProvider.remove("accountInfo");
            }
            if (await StorageProvider.get("currencyCode")) {
                await StorageProvider.remove("currencyCode");
            }
            console.log('@@@ Profile Data =======', profileData, );
            if (profileData?.attributes?.user_type === "student") {
                if(profileData?.attributes?.currency_code === null){
                    const profileData = responseJson.user.data;
                    console.log('@@@ Profile Data ======= 111', profileData.attributes);
                    const token = responseJson.meta.token;
                    await StorageProvider.set("authToken", token);
                    await StorageProvider.set("accountInfo", JSON.stringify(profileData));
                    localStorage.setItem("id", responseJson.user.data.id);
                    localStorage.setItem("token", token);
                    localStorage.setItem("accountInfo", JSON.stringify(profileData)); 
                    this.props.history.push({
                        pathname: "/currencypage",
                        state: responseJson,
                      });   
                }else{
                    console.log("@@@ profile 1", profileData?.attributes);
    
                    toast.success("Logged In Successfully");
                    await StorageProvider.set("authToken", token);
                    await StorageProvider.set("accountInfo", JSON.stringify(profileData));
                    localStorage.setItem("accountInfo", JSON.stringify(profileData));
                    console.log("@@@ profile 2", profileData?.attributes);
                    await StorageProvider.set("currencyCode", profileData?.attributes?.currency_code);
                    console.log("@@@ profile update", profileData?.attributes?.currency_code);
                    if (profileData?.attributes?.currency_code === null) {
                        this.getAllCountryCurrency();
                    } else {
                        if (profileData?.attributes?.currency_code == "USD") {
                            localStorage.setItem("symbol", "$");
                            localStorage.setItem("currency_code", "USD");
                        } else if (profileData?.attributes?.currency_code == "INR") {
                            localStorage.setItem("symbol", "₹");
                            localStorage.setItem("currency_code", "INR");
                        } else if (profileData?.attributes?.currency_code == "CAD") {
                            localStorage.setItem("symbol", "CA$");
                            localStorage.setItem("currency_code", "CAD");
                        } else if (profileData?.attributes?.currency_code == "GBP") {
                            localStorage.setItem("symbol", "£");
                            localStorage.setItem("currency_code", "GBP");
                        }
                        this.props.history.push("/Catalogue/Home");
                    }
                }
            } else if (profileData?.attributes?.user_type === "publisher") {
                toast.success("Logged In Successfully");
                await StorageProvider.set("publisherToken", token);
                await StorageProvider.set("accountInfo", JSON.stringify(profileData));
                this.props.history.push("/publisher");
            } else if (
                profileData?.attributes?.user_type === "admin" ||
                profileData?.attributes?.user_type === "super_admin"
            ) {
                toast.success("Logged In Successfully");
                await StorageProvider.set("adminToken", token);
                await StorageProvider.set("accountInfo", JSON.stringify(profileData));
                this.props.history.push("/AdminConsole");
            } else {
                toast.error("Some Error Happened");
            }
        } else {
            toast.error(responseJson.errors);
        }
    }

    sendLoginFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }

    sendLoginSuccessMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));
        msg.addData(getName(MessageEnum.LoginUserName), this.state.email);
        msg.addData(getName(MessageEnum.CountyCodeDataMessage), null);
        msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
        msg.addData(
            getName(MessageEnum.LoginIsRememberMe),
            this.state.checkedRememberMe
        );
        this.send(msg);
    }

    openInfoPage() {
        const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    goToForgotPassword() {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationForgotPasswordMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "email");
        this.send(msg);
    }

    goToSocialLogin() {
        const msg: Message = new Message(
            getName(MessageEnum.NavigationSocialLogInMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    goToRegistration() {
        const msg: Message = new Message(
            getName(MessageEnum.NavigateEmailSignUpMessage)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(msg);
    }

    doEmailLogIn(values: any): boolean {

        const header = {
            "Content-Type": configJSON.loginApiContentType,
        };

        const attrs = {
            email: values.email,
            password: values.password,
        };

        const data = {
            type: "email_account",
            attributes: attrs,
        };
        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.apiEmailLoginCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.loginAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );
        this.props.showLoader();
        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleChange = (value: any) => {
        this.setState({ otp: value }, () => { console.log("@@@value", value); });
    }


    SubmmitOtp = () => {

        // let getToken = localStorage.getItem("token")
        const Token: any = localStorage.getItem('token')
    }


    doForgotPassword(values: any): boolean {
        let getToken = localStorage.getItem("token")
        this.setState({
            email: values.email
        })

        const header = {
            "Content-Type": configJSON.loginApiContentType,
        };
        const httpBody =
        {
            "data": {
                "attributes": {
                    "email": values.email
                }
            }
        }
        console.log("httpBody@@@@@", httpBody)
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.forgotPassword = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.forgotPasswordEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );
        // this.props.showLoader();
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    settingToken = async (token: any) => {

        StorageProvider.set("resetToken", token);
    }

    getAllCountryCurrency = async () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        const headers = { "Content-Type": configJSON.loginApiContentType };

        this.getCountryCurrencyAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getCountryCurrencyEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }



    currencyHandleChange = (e: any, value: any) => {
        if (value != null) {
            this.setState({
                selectedCountryError: false
            })
            this.setState({
                selectedCountry: value.name
            });
        } else {
            this.setState({
                selectedCountryError: true
            })
            this.setState({
                selectedCountry: null
            });
        }
    }

    updateCountry = async () => {
        if (this.state.selectedCountry === null) {
            this.setState({
                selectedCountryError: true
            })
            return false
        } else {
            this.setState({
                selectedCountryError: false
            })
            let accountInfo = await StorageProvider.get("accountInfo");
            accountInfo = JSON.parse(accountInfo);

            const header = {
                "Content-Type": configJSON.loginApiContentType,
            };
            const httpBody = {
                "id": accountInfo?.id,
                "country_name": this.state.selectedCountry
            }
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.updateCountryApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateCountryAPiEndPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.loginAPiMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        }
    }
}
