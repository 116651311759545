import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
export const configJSON = require("./config");
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import AppHeader from "../../../components/src/Header/index";
import HomeController, { Props } from "./HomeController.web";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { withRouter } from "react-router-dom";
import withTheme from "../../../components/src/Theme/withTheme";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import "../../../components/src/Styles/Styles.css";
import "../assets/css/common.css";

import {
    mastercard,
    bookPass,
    checkMarked,
    paymentActive,
    paymentInactive,
    IconClose
} from "./assets";
import SideBar from "./Sidebar.web";
import Loader from "../../../components/src/Loader.web";
const styles = {
    cardRoot: {
        maxWidth: "400px",
        margin: "20px",
    },
} as any;
class MyPackages extends HomeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        const { classes } = this.props;
        const { subscription_monthly_payment_history, SubscriptionMonthlyError, subscription_onetime_payment_history, SubscriptionOnetimeError } = this.state;
        const have_package = this.state.accountInfo && this.state.accountInfo?.attributes?.have_package;

        console.log(this.state.myPackages, "===@@@")
        return (
            <>
                <Loader loading={this.state.Loader} />
                <AppHeader />
                <div className={`catalog-book mypackages_sec ${this.props.mode && "dark3"}`}>
                    <SideBar
                        selectedIndex={6}
                        accountInfo={this.state.accountInfo && this.state.accountInfo}
                    />
                    <div className="content">
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            <Link href="/Catalogue/Home" style={{ color: "#3b9dd4" }}>
                                Home
                            </Link>
                            <Typography style={{ color: "#797b79 !important" }}>My Packages</Typography>
                        </Breadcrumbs>
                        <h3>My Packages</h3>

                        <div className="my-package-wrapper">
                            <Grid container spacing={4}>
                                <Grid item lg={8}>
                                    {Array.isArray(this.state.myPackages) && this.state.myPackages.length > 0 ?
                                        <>
                                            <p className="cancelPass"><span onClick={() => { this.props.history.push('/BuyPackages') }}>Buy Packages</span></p>
                                            {this.state.myPackages.length > 0 ? <p>You have {this.state.myPackages.length} packages available.</p> : null}
                                            {this.state.myPackages.map((x: any, index: any) => {
                                                return (
                                                    <>
                                                        <div className={`book-pass mt-20 ${this.props.mode && "dark1"}`} key={index}>
                                                            <div className="left_view">
                                                                <div className="active-seaction">
                                                                    <span>{x.attributes.is_active ? "Active" : "In Active"}</span>
                                                                </div>
                                                                <div className="book-review gradient-bg mt-20 mb-20">
                                                                    <div className="img-section">
                                                                        <img src={bookPass} />
                                                                    </div>
                                                                    <div className="description-section">
                                                                        <p className="first-p text-bold">BOOK PASS</p>
                                                                        <p>Read/Play all available books</p>
                                                                    </div>
                                                                </div>
                                                                <p
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            readSetModal: true,
                                                                            booksChecked: [x.attributes.order_id, x.attributes.subscription_package.id]
                                                                        })
                                                                    }}
                                                                    className="cancelPass"
                                                                ><span>Cancel Book Pass</span></p>
                                                                <div className="available-feature mt-20 mb-20">
                                                                    <p>Available Features</p>
                                                                    <ul>
                                                                        <li> <img src={checkMarked} /> Read/Play ebook anytime, anywhere</li>
                                                                        <li> <img src={checkMarked} /> Download ebooks</li>
                                                                    </ul>
                                                                </div>
                                                                {x.attributes.no_of_book_left_in_package ?
                                                                    <p className="cancelPass">{x.attributes.no_of_book_left_in_package} more books available in this package. <span onClick={() => {
                                                                        let left_book = x.attributes.no_of_book_left_in_package
                                                                        this.props.history.push(`/package-books/${left_book > 0 ? left_book : x.attributes.subscription_package.no_of_books}?package_id=${x.attributes.subscription_package.id}&order_id=${x.attributes.order_id}`)
                                                                    }}>Click here</span> to buy.</p>
                                                                    :
                                                                    <p className="cancelPass" >No more books available in this package.</p>
                                                                    // {`store-items-block-wrapper ${this.props.mode && "dark1"}`}
                                                                }
                                                            </div>

                                                            <div className="billing_view">
                                                                <p className="text-bold"> {`Valid till ${moment(x.attributes.end_date).format('DD MMM, YYYY')}`}</p>
                                                                <div className={`next-biling ${this.props.mode && "dark2"}`}>
                                                                    <div>
                                                                        <p className="text-bold">Last Renewed</p>
                                                                        <p>{x.attributes.subscription_package.updated_at ? moment(x.attributes.subscription_package.updated_at).format('DD MMM, YYYY') : "-"}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-bold">Next Billing</p>
                                                                        <p>{x.attributes.end_date ? moment(x.attributes.end_date).format('DD MMM, YYYY') : "-"}</p>
                                                                    </div>
                                                                    <div>
                                                                        <p className="text-bold">Available Books</p>
                                                                        <p>{x.attributes.no_of_book_left_in_package ? x.attributes.no_of_book_left_in_package : "0"}/{x.attributes.subscription_package.no_of_books ? x.attributes.subscription_package.no_of_books : "0"} Books</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            })
                                            }
                                        </>
                                        :
                                        null}
                                </Grid>

                                <Grid item lg={4}>
                                    {subscription_monthly_payment_history && subscription_onetime_payment_history &&
                                        <div className={`right-part ${this.props.mode && "dark1"}`}>
                                            <div className="book_purchase_history_row">
                                                <img src={this.props.mode ? paymentActive : paymentInactive} />
                                                <strong className="title">Previous Subscription</strong>
                                            </div>
                                            <div className={`purchase-history-card block-details ${this.props.mode && "dark2"}`}>
                                                <span className="small_heading">Subscription Ended</span>
                                                <span className="tag-line">Recurring Per Month</span>
                                                {subscription_monthly_payment_history && (
                                                    <ul>
                                                        {subscription_monthly_payment_history && subscription_monthly_payment_history.map((item: any, index: any) => {
                                                            return (
                                                                <li key={index}>
                                                                    <div className="fisrt-section">
                                                                        <img
                                                                            src={mastercard}
                                                                            alt="master-card"
                                                                            className="master-card"
                                                                        />
                                                                        <div className="discription-part">
                                                                            <p className={`${this.props.mode && "darkFo"}`}>
                                                                                {item?.attributes?.order_transactions[0]?.order_id ? item?.attributes?.order_transactions[0]?.order_id : "-"}
                                                                            </p>
                                                                            <strong>
                                                                                <span>••••</span> <span>••••</span>
                                                                                <span className="color-gray">{item?.attributes?.order_transactions[0]?.last4 ? item?.attributes?.order_transactions[0]?.last4 : "-"}</span>
                                                                            </strong>
                                                                        </div>
                                                                    </div>

                                                                    <div className={`price-part ${this.props.mode && "darkFont"}`} >
                                                                        <p>{item?.attributes?.order_transactions[0]?.created_at ? moment(item?.attributes?.order_transactions[0]?.created_at).format('DD MMM, YYYY') : "-"}</p>
                                                                        <div className="price-row">
                                                                            <span>{item.attributes.order_transactions[0].currency}</span>
                                                                            <h5>{item?.attributes?.order_transactions[0]?.amount ? item?.attributes?.order_transactions[0]?.amount : "-"}</h5>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                )}
                                                {SubscriptionMonthlyError && (
                                                    <div className="text-center clear_both">
                                                        <span className="errorcountry">{SubscriptionMonthlyError}</span>
                                                    </div>
                                                )}
                                            </div>

                                            <div className={`purchase-history-card block-details ${this.props.mode && "dark2"}`}>
                                                <span className="small_heading">Subscription Ended</span>
                                                <span className="tag-line">One Time Payment</span>
                                                {subscription_monthly_payment_history && (
                                                    <ul>
                                                        {subscription_onetime_payment_history && subscription_onetime_payment_history.map((item: any, index: any) => {
                                                             let x = item?.attributes?.order_transactions[0]?.currency
                                                           return (
                                                                <li key={index}>
                                                                    <div className="fisrt-section">
                                                                        <img
                                                                            src={mastercard}
                                                                            alt="master-card"
                                                                            className="master-card"
                                                                        />
                                                                        <div className="discription-part">
                                                                            <p className={`${this.props.mode && "darkFo"}`}>
                                                                            {item?.attributes?.order_transactions[0]?.order_id ? item?.attributes?.order_transactions[0]?.order_id : "-"}
                                                                            </p>
                                                                            <strong>
                                                                                <span>••••</span> <span>••••</span>
                                                                                <span className="color-gray">{item?.attributes?.order_transactions[0]?.last4 ? item?.attributes?.order_transactions[0]?.last4 : "-"}</span>
                                                                            </strong>
                                                                        </div>
                                                                    </div>

                                                                    <div className={`price-part ${this.props.mode && "darkFont"}`} >
                                                                        <p>{item?.attributes?.order_transactions[0]?.created_at ? moment(item?.attributes?.order_transactions[0]?.created_at).format('DD MMM, YYYY') : "-"}</p>
                                                                        <div className="price-row">
                                                                            <span>{ x === "INR" ? "₹" : x === "$" ? "$" : x === "CA$" ? "CA$" : x === "£" ? "£":""}</span>
                                                                            <h5>{item?.attributes?.order_transactions[0]?.amount ? item?.attributes?.order_transactions[0]?.amount : "-"}</h5>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                )}
                                                {SubscriptionMonthlyError && (
                                                    <div className="text-center clear_both">
                                                        <span className="errorcountry">{SubscriptionMonthlyError}</span>
                                                    </div>
                                                )}
                                            </div>

                                          
                                        </div>
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>

                <Modal
                    open={this.state.readSetModal}
                    onClose={this.handleReadSetModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="cancel-book-section d-flex justify-content-center align-items-center"
                >
                    <div className={`cancel-book-wrapper ${this.props.mode && "dark2 darkFont"}`}>
                        <img
                            src={require("../assets/images/Close_Icon.png").default}
                            alt="close"
                            className="close-icon"
                            onClick={() => this.handleReadSetModalClose()}
                        />
                        <div className="inside-wrapper-box">
                            <h4 className={`text-center ${this.props.mode && "darkFo"}`}>Are You Sure?</h4>
                            {/* <p className="text-center sub-title">Cancel Your 4 Months Book Pass</p> */}
                            <div className="book-review gradient-bg mt-20">
                                <div className="img-section">
                                    <img src={bookPass} />
                                </div>
                                <div className="description-section">
                                    <p className="first-p">BOOK PASS</p>
                                    <p>
                                        <small>Read/Play all available books</small>
                                    </p>
                                </div>
                            </div>

                            <div className="btns ">
                                <button
                                    className="button button-primary w-100 m-b-5"
                                    onClick={() => { this.postBookPassCancel(this.state.booksChecked[0], this.state.booksChecked[1]) }}
                                >
                                    Cancel Book Pass
                                </button>
                                <button className={`button button-secondary w-100 ${this.props.mode && "dark3"}`} onClick={() => this.handleReadSetModalClose()}>Cancel</button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}
//@ts-ignore
const MyPackagesRouter = withRouter(MyPackages);
const MyPackagesTheme = withTheme(MyPackagesRouter);
export default withStyles(styles)(MyPackagesTheme);
// Customizable Area Start
// Customizable Area End