import React, { Component } from 'react'
import OtpInput from 'react-otp-input';
import './ForgotPasswordOtp.css'
import { MdCancel } from "react-icons/md"
import ForgotPasswordOtpController from './ForgotPasswordOtpController.web';
export class ForgotPasswordOtp extends ForgotPasswordOtpController {





    render() {
        return (
            <div className='Container'>
                <div className='main_container'>
                    <div className='forgot_otp_head'>
                        <div className='headding'>
                            <h1>Enter Otp</h1>
                            <MdCancel className='otp_cancel' onClick={this.handleClose} />
                        </div>
                        <form
                            className='form_otp'
                            onSubmit={(event) => {
                                event.preventDefault()
                                this.onSubmitOtp()
                            }
                            }
                        >
                            <div className="inner_forgott">
                                <OtpInput

                                    className='input_class'
                                    value={this.state.otp}
                                    onChange={(otp: any) => this.setState({ otp })}
                                    numInputs={4}
                                    separator={<span className='span_inpute'>-</span>}

                                    inputStyle={{
                                        width: "3rem",
                                        height: "3rem",
                                        margin: "0 1rem",
                                        fontSize: "2rem",
                                        borderRadius: 4,
                                        border: "1px solid rgba(0,0,0,0.3)"
                                    }}
                                />
                            </div>

                            <div className="btn">
                                <button  className='submmit_btn'>
                                    Submit
                                </button>
                            </div>

                        </form>
                    </div>

                </div>
            </div>
        )
    }
}

export default ForgotPasswordOtp