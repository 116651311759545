import React, { Component } from 'react'
import './QrCodeDetail.css'
import { UserImg } from './assets'
import QrCodeDetailController, {
    Props,
  } from "./QrCodeDetailController.web";



class QrCodeDetail extends QrCodeDetailController {
    render() {
        return (

            <div className="main_container">
                <div className="container">
                    <div className="topDiv"></div>
                    <div className="bottomDiv">
                        <div className="userimg"> <img src={UserImg} className=" userimg" />
                        </div>
                        <div className="user_details">
                            <label className="labelIn">First Name :<p className="userName">{this.state.getUserDetail.full_name}</p></label>
                            
                            <hr />
                            <label className="labelIn">Email : <p className="username">{this.state.getUserDetail.email}</p> </label>
                            <hr />
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default QrCodeDetail;