export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgdummy = require("../assets/defult.svg");
export const IconClose = require("../assets/icon-close.png");
export const CardImage = require("../assets/card-img.png");
export const SecureImage = require("../assets/secure-img.png");
export const MsgRecev = require("../assets/msg-received.png");
export const RightIcon = require("../assets/right-icon.png");
export const LockIcon = require("../assets/lock-icon.png");
export const EmptyCart = require("../assets/empty-cart-img.png");
export const Congrats = require("../assets/congrats.png");

