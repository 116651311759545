import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import HomeController, { Props, S } from "./HomeController.web";
export const configJSON = require("./config");
import SideBar from "./Sidebar.web";
import AppHeader from "../../../components/src/Header/index";
import BookListing from "../../../components/src/BookListing/index";
// import SimilarBook from "../../../components/src/SimilarBook/index";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import Link from "@material-ui/core/Link";
import "../assets/css/common.css";
import withTheme from "../../../components/src/Theme/withTheme";
import "../assets/css/Instore.css";

import {
    bookLogo,
    playButton,
    Published,
    selectArrow,
    downloads,
    Notes,
    earnings,
    subscription,
    paymentHistory,
    purchasedNotes,
} from "./assets";
import SimilarBook from "../../../components/src/BookListing/similarBook";
//import styles from '../assets/css/index'

// Customizable Area Start     
const styles = {
    searchIcon: {
        // padding: theme.spacing(0, 2),
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        left: "3%",
        alignItems: "center",
        justifyContent: "center",
    },

    search: {
        position: "relative",
        background: "#5196DD",
        // border:"1px solid red",
        // backgroundCo #4CAEEF
        color: "white",
        //   backgroundColor:"rgb(68, 156, 214)",
        //   marginLeft:"0",
        width: "100%",
        borderRadius: "8px",
        marginTop: "2%",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        // transition: theme.transitions.create('width'),
        width: "100%",
        padding: "13px 50px",
    },
    // notesForSale: {
    //   backgroundColor: "#F2F3F2",
    //   height: "auto",
    //   margin: "20px",
    //   borderRadius: "8px",
    // },
    cardContainer: {
        height: "auto",
        backgroundColor: "white",
        width: "auto",
        margin: "10px",
        borderRadius: "8px",
    },
    formControl: {
        // margin: "23px",

        minWidth: 120,
    },
} as any;
// Customizable Area End

// Customizable Area Start    
class viewAllBooks extends HomeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    render() {
        const { classes } = this.props;
        const handleBookfilter = this.state.allBooks && this.state.allBooks.filter((item: any) => {
            return item.attributes.is_purchased === false
        })

        console.log(handleBookfilter, "===@@@")

        return (
            <>
                <Loader loading={this.state.Loader} />
                <AppHeader />
                <div className={`view-book-page catalog-home ${this.props.mode && "dark3"}`}>
                    <SideBar
                        selectedIndex={7}
                        className="col-2"
                        accountInfo={this.state.accountInfo && this.state.accountInfo}
                    />
                    <div className="content">
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            <Link href="/Catalogue/Home" style={{ color: "#3b9dd4" }}>
                                Home
                            </Link>

                            <Typography style={{ color: "#797b79 !important" }}>
                                In Store
                            </Typography>
                        </Breadcrumbs>
                        <h3>In store</h3>

                        {this.state.allBooks && (
                            <div className={`book-list-wrapper`}>
                                <Grid container spacing={4}>
                                    {this.state.allBooks && handleBookfilter.map((x: any, index: any) => {
                                        return (
                                            (this.state.viewAll ? index < this.state.allBooks.length : index < 9) && (
                                                <Grid item md={4} key={x.id} >
                                                    <div className={`item_viewall ${this.props.mode && "dark1 "}`}>
                                                        <div className="img-part"
                                                            onClick={() => {
                                                                //@ts-ignore
                                                                this.props.history.push(`/Catalogue/BookDetails/${x.attributes.id}`);
                                                            }}
                                                        >
                                                            <img src={x?.attributes?.cover_image_url} alt="book-img" />
                                                        </div>
                                                        <div className="details">
                                                            <div className="left instore-left-part">
                                                                <span onClick={() => {
                                                                    //@ts-ignore
                                                                    this.props.history.push(`/Catalogue/BookDetails/${x.attributes.id}`);
                                                                }}
                                                                >
                                                                    {`${x.attributes.name} : ${x.attributes.author}`}
                                                                </span>
                                                                <small>
                                                                    {x.attributes.author}
                                                                </small>
                                                            </div>
                                                            <div className="right instore-right-part">
                                                                {/* <strong className="color-gray">
                                                                        $45
                                                                    </strong> */}
                                                                <strong className="color-black">
                                                                    {this.state.symbol}{x?.attributes?.full_price ? x?.attributes?.full_price : "-"}
                                                                </strong>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Grid>
                                            )
                                        );
                                    })}
                                </Grid>

                                {!this.state.viewAll && (this.state.allBooks && this.state.allBooks.length > 9) && (
                                    <button className="View-all" onClick={() => { this.setState({ viewAll: true }) }}>
                                        View All
                                    </button>
                                )}

                                {!this.state.viewAll && (
                                    <div className={`store-items-block-wrapper ${this.props.mode && "dark1"}`}>
                                        <h3>Best Seller</h3>
                                        {/* <BookListing
                                            allBooks={
                                                this.state.bestSeller && this.state.bestSeller
                                            }
                                        /> */}

                                        <SimilarBook  allBooks={
                                                this.state.bestSeller && this.state.bestSeller
                                            }  />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };
};
// Customizable Area End


const viewAllBooksWithRouter = withRouter(viewAllBooks);
const viewAllBooksTheme = withTheme(viewAllBooksWithRouter);
export default withStyles(styles)(viewAllBooksTheme);

   // Customizable Area Start
  // Customizable Area End