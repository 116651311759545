import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
export const configJSON = require("./config");
import "../../../components/src/Styles/Styles.css";
import "../assets/css/common.css";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import AppHeader from "../../../components/src/Header/index";
import HomeController, { Props } from "./HomeController.web";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { withRouter } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import moment from "moment";
import {
    playButton,
    paymentHistory,
    userIcon,
    mastercard,
    IconClose,
    paymentActive,
    bookPass,
    menuLightMode,
} from "./assets";
import SideBar from "./Sidebar.web";
import SimpleMenu from "./bookMenu.web";
import Loader from "../../../components/src/Loader.web";
import "../assets/css/common.css";
import withTheme from "../../../components/src/Theme/withTheme";

// Customizable Area Start
const styles = {
    cardRoot: {
        maxWidth: "400px",
        margin: "20px",
    },
} as any;
// Customizable Area End

// Customizable Area Start
class MyBooks extends HomeController {
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        const { classes } = this.props;
        const { monthly_payment_history, onetime_payment_history, monthlyOnetimeError, monthlyError } = this.state;
        console.log(this.state.myBooks, "===@@@")
        return (
            <>
                <Loader loading={this.state.Loader} />
                <AppHeader />

                <div className="catalog-book my-earnings">
                    <SideBar
                        selectedIndex={1}
                        accountInfo={this.state.accountInfo && this.state.accountInfo}
                    />
                    <div className="content">
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="medium" />}
                            aria-label="breadcrumb"
                        >
                            <Link href="/Catalogue/Home" style={{ color: "#3b9dd4" }}>
                                Home
                            </Link>
                            <Typography style={{ color: "#797b79 !important" }}>
                                My Books
                            </Typography>
                        </Breadcrumbs>
                        <h3>My Books</h3>
                        <p className="color-gray">{(this.state.myBooks && this.state.myBooks.length) || 0} Books Available</p>

                        <div className="card-list-wrapper my-books-new-wrapper">
                            <div className="left-part">
                                <div className="row">
                                    {this.state.myBooks && (
                                        <div className="card" id="my-books-cards">
                                            {this.state.myBooks && this.state.myBooks.map((x: any, index: any) => {
                                                return (
                                                    <div className="col-12 col-sm-6 col-lg-12">
                                                        <React.Fragment key={index}>
                                                            <div className="d-flex inner-divs">
                                                                <div className={`list ${this.props.mode && "dark1"}`}>
                                                                    <div className="img-part">
                                                                        <img src={x.attributes.cover_image_url}
                                                                            alt="image"
                                                                            onClick={() => {
                                                                                //@ts-ignore
                                                                                this.props.history.push(`/book-read/${x.attributes.id}`);
                                                                                localStorage.setItem("MyNoteBookNo", x.attributes.id)
                                                                                // localStorage.setItem("boookid",x.attributes.id)
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="text">
                                                                        <strong>{x.attributes.name}</strong>
                                                                        <small className={`color-gray ${this.props.mode && "darkFo"}`}>
                                                                            {x.attributes.book_type}
                                                                        </small>
                                                                        <div className="details">
                                                                            <span>
                                                                                {x.attributes.author}
                                                                            </span>
                                                                            <span className="color-blue d-block">
                                                                                {x.attributes.note_count}{" "} Noted Available
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                    <img
                                                                        src={playButton}
                                                                        alt="play"
                                                                        className="play-btn"
                                                                        onClick={() => {
                                                                            //  this.props.history.push(`/book-read/${x.id}`);
                                                                             this.props.history.push(`/book-read/${x.attributes.id}`);
                                                                             localStorage.setItem("MyNoteBookNo", x.attributes.id)
                                                                            }}
                                                                    />
                                                                </div>
                                                                <div
                                                                    className="more"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            returnBookId: [x.id, x.attributes.order_id, x.attributes.cover_image_url],
                                                                        });
                                                                    }}
                                                                >

                                                                    {/* <button className="btn" /> */}
                                                                    <SimpleMenu
                                                                        handleReadSetModalClose={this.handleReadSetModalClose}
                                                                        mode={this.props.mode}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className={`right-part ${this.props.mode && "dark1"}`}>
                                {monthly_payment_history && onetime_payment_history &&
                                    <>
                                        <div className="book_purchase_history_row">
                                            <img
                                                src={this.props.mode ? paymentActive : paymentHistory}
                                            />
                                            <strong className="title">Book Purchase History</strong>
                                        </div>

                                        <div className={`purchase-history-card block-details ${this.props.mode && "dark2"}`}>
                                            <span className="tag-line">Recurring Per Month</span>
                                            {/* <div className={`card-header ${this.props.mode && "dark-mode"}`}>
                                            <img
                                                src={userIcon}
                                                alt="The world of Abstract Art"
                                                className="book-img"
                                            />
                                            <div className="right-block">
                                                <h6 className={`title ${this.props.mode && "darkFo"}`}>The world of Abstract Art</h6>
                                                <span>Thriller</span>
                                                <h5 className={`${this.props.mode && "darkFo"}`}>Emily Robbins</h5>
                                            </div>
                                        </div> */}
                                            {/* <span>Subscription Ended</span> */}

                                            {monthly_payment_history && (
                                                <ul>
                                                    {monthly_payment_history && monthly_payment_history.map((item: any, index: any) => {
                                                        let x = item?.attributes?.currency
                                                        // var currency_sym = x === "INR" ? "₹" : x === "USD" ? "$" : x === "CAD" ? "CA$" : x === "GBP" ? "£":"" ;

                                                        // if (item?.attributes?.currency === "INR") {
                                                        //     var currency_sym = "₹"
                                                        // } else if (item?.attributes?.currency === "USD") {
                                                        //     var currency_sym = "$"
                                                        // } else if (item?.attributes?.currency === "CAD") {
                                                        //     var currency_sym = "CA$"
                                                        // } else if (item?.attributes?.currency === "GBP") {
                                                        //     var currency_sym = "£"
                                                        // } else {
                                                        //     var currency_sym = ""
                                                        // }

                                                        return (
                                                            <li key={index}>
                                                                <div className="fisrt-section">
                                                                    <img
                                                                        src={mastercard}
                                                                        alt="master-card"
                                                                        className="master-card"
                                                                    />
                                                                    <div className="discription-part">
                                                                        <p className={`${this.props.mode && "darkFo"}`}>
                                                                            {item?.attributes?.order_id ? item?.attributes?.order_id : "-"}
                                                                        </p>
                                                                        <strong>
                                                                            <span>••••</span> <span>••••</span>
                                                                            <span className="color-gray">{item?.attributes?.last4 ? item?.attributes?.last4 : "-"}</span>
                                                                        </strong>
                                                                    </div>
                                                                </div>

                                                                <div className={`price-part ${this.props.mode && "darkFont"}`} >
                                                                    <p>{item?.attributes?.created_at ? moment(item?.attributes?.created_at).format('DD MMM, YYYY') : "-"}</p>
                                                                    <div className="price-row">
                                                                        <span>
                                                                            {x === "INR" ? "₹" : x === "USD" ? "$" : x === "CAD" ? "CA$" : x === "GBP" ? "£" : ""}

                                                                        </span>
                                                                        <h5>{item?.attributes?.amount ? item?.attributes?.amount : "-"}</h5>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            )}
                                            {monthlyError && (
                                                <div className="text-center clear_both">
                                                    <span className="errorcountry">{monthlyError}</span>
                                                </div>
                                            )}
                                        </div>

                                        <div className={`purchase-history-card block-details ${this.props.mode && "dark2"}`}>
                                            <span className="tag-line">One Time Payment</span>

                                            {onetime_payment_history && (
                                                <ul>
                                                    {onetime_payment_history && onetime_payment_history.map((item: any, index: any) => {
                                                        if (item?.attributes?.currency === "INR") {
                                                            var currency_sym = "₹"
                                                        } else if (item?.attributes?.currency === "USD") {
                                                            var currency_sym = "$"
                                                        } else if (item?.attributes?.currency === "CAD") {
                                                            var currency_sym = "CA$"
                                                        } else if (item?.attributes?.currency === "GBP") {
                                                            var currency_sym = "£"
                                                        } else {
                                                            var currency_sym = ""
                                                        }

                                                        return (
                                                            <li key={index}>
                                                                <div className="fisrt-section">
                                                                    <img
                                                                        src={mastercard}
                                                                        alt="master-card"
                                                                        className="master-card"
                                                                    />
                                                                    <div className="discription-part">
                                                                        <p className={`${this.props.mode && "darkFo"}`}>
                                                                            {item?.attributes?.order_id ? item?.attributes?.order_id : "-"}
                                                                        </p>
                                                                        <strong>
                                                                            <span>••••</span> <span>••••</span>
                                                                            <span className="color-gray">{item?.attributes?.last4 ? item?.attributes?.last4 : "-"}</span>
                                                                        </strong>
                                                                    </div>
                                                                </div>

                                                                <div className={`price-part ${this.props.mode && "darkFont"}`} >
                                                                    <p>{item?.attributes?.created_at ? moment(item?.attributes?.created_at).format('DD MMM, YYYY') : "-"}</p>
                                                                    <div className="price-row">
                                                                        <span>{currency_sym}</span>
                                                                        <h5>{item?.attributes?.amount ? item?.attributes?.amount : "-"}</h5>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            )}
                                            {monthlyOnetimeError && (
                                                <div className="text-center clear_both">
                                                    <span className="errorcountry">{monthlyOnetimeError}</span>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    open={this.state.readSetModal}
                    onClose={this.handleReadSetModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className="cancel-book-section d-flex justify-content-center align-items-center"
                >
                    <div className="cancel-book-wrapper">
                        <img
                            src={require("../assets/images/Close_Icon.png").default}
                            alt="close"
                            className="close-icon"
                            onClick={() => this.handleReadSetModalClose()}
                        />
                        <div className="inside-wrapper-box">
                            <h4 className="text-center">Are You Sure?</h4>
                            {/* <p className="text-center sub-title"> You have not read this book yet! </p> */}

                            <div className="text-center m-t-20">
                                <img
                                    src={this.state.returnBookId[2]}
                                    style={{ width: "100px", borderRadius: "5px" }}
                                    alt="Book cover"
                                />
                            </div>
                            {/* <p className="sub-title text-center m-t-35 m-b-35"> You returning this book after 1 week <br /> of purchase. Refund is not allowed here. <br /> still want to proceed with refund ? </p> */}
                            <div className="btns ">
                                <button
                                    className="button button-primary w-100 m-b-5"
                                    onClick={() => {
                                        this.state.returnBookId &&
                                            this.postRefundBook(
                                                this.state.returnBookId[0],
                                                this.state.returnBookId[1]
                                            );
                                    }}
                                >
                                    Return Now
                                </button>
                                <p
                                    className="cancelBtn"
                                    onClick={() => this.handleReadSetModalClose()}
                                >
                                    Cancel
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        );
    }
}
// Customizable Area End
//@ts-ignore
const MyBooksRouter = withRouter(MyBooks);
const MyBooksTheme = withTheme(MyBooksRouter);
export default withStyles(styles)(MyBooksTheme);

// Customizable Area Start
// Customizable Area End
