export const bookCover = require("../assets/images/bookCover.png");
export const homeIcon = require("../assets/images/image_Home.png");
export const homeIconActive = require("../assets/images/group_HomeIcon_Active.png");
export const viewIcon = require("../assets/images/Views.png");
export const userIcon = require("../assets/images/user.png");
export const bookLogo = require("../assets/images/peddler.png");
export const playButton = require("../assets/images/Play Button.png");
export const Published = require("../assets/images/Published Note_Icon.png");
export const selectArrow = require("../assets/images/Select_Arrow.png");
export const downloads = require("../assets/images/Downloads_Inactive.png");
export const downloadsActive = require("../assets/images/Downloads_Active.svg");
export const downloadsActiveBlue = require("../assets/images/Downloads_active.png");
export const bookRead = require("../assets/images/icon-pause.png");
export const lockIcons = require("../assets/images/icon-lock.png");
export const pdfIcon = require("../assets/images/group_PDF Icon.png");
export const Notes = require("../assets/images/Notes_Inactive.png");
export const NotesActive = require("../assets/images/Notes_Active (1).png");
export const earnings = require("../assets/images/Earnings_Inactive.svg");
export const dropdown = require("../assets/images/🤸‍♂️Action Icon@2x.svg");
export const earningsActive = require("../assets/images/Earnings_Active.png");
export const markRead = require("../assets/images/view_Mark as Read.png");
export const readSetting = require("../assets/images/view_Settings.png");
export const menuLightMode = require("../assets/images/menu_lightmode.png");
export const menuDarkMode = require("../assets/images/image_Overflowmenu Icon.png");
export const viewAll = require("../assets/images/View All _ Icon.png");
export const subscription = require("../assets/images/Subscription_Inactive.svg");
export const subscriptionActive = require("../assets/images/Subscription_Active.svg");
export const refund = require("../assets/images/view_Icon Refund.png");
export const bell = require("../assets/images/view_Icon Refund.png");
export const paymentHistory = require("../assets/images/Payment History_Icon_Inactive.svg");
export const purchasedNotes = require("../assets/images/Purchased Notes_Inactive.png");
export const purchasedNotesActive = require("../assets/images/Purchased Notes_Active.png");
export const paymentActive = require("../assets/images/Payment History_Icon_Active.svg");
export const paymentInactive = require("../assets/images/Payment History_Icon_Inactive.svg");
export const viewerIcon = require("../assets/images/Viewers Icon.png");
export const downloadIcon = require("../assets/images/ICON Downloads.png");
export const dollerIcon = require("../assets/images/Earnings_Icon.png");
export const sampleReadIcon = require("../assets/images/Group_Subscription.svg");
export const searchImage = require("../assets/images/Search.png");
export const cartIcon = require("../assets/images/Add to Cart.png");
export const EditIcon = require("../assets/images/Edit_Icon.png");
export const logOut = require("../assets/images/Logout_Inactive.png");
export const logOutActive = require("../assets/images/Logout_active.png");
export const mastercard = require("../assets/images/Master Card.png");
export const bookPass = require("../assets/images/Book pass.png");
export const checkMarked = require("../assets/images/view_Checkmarked Icon.png");
export const download = require("../assets/images/Icon_1.png");
export const dollar = require("../assets/images/Icon_2.png");
export const star = require("../assets/images/Icon_3.png");
export const eye = require("../assets/images/Viewers Icon.png");
export const next = require("../assets/images/image_NEXT Month.png");
export const prev = require("../assets/images/image_PREV Month.png");
export const IconClose = require("../assets/images/Close_Icon.png");
export const DeleteClose = require("../assets/images/Delete_Icon.png");
export const cartDot = require("../assets/images/cart-with-dot.png");
export const cart = require("../assets/images/cart-without-dot.png");
export const storeActive = require("../assets/images/Active In Store Icon.png");
export const storeInActive = require("../assets/images/Static In Store Icon.png");
export const darkActive = require("../assets/images/Dark Mode_Active.png");
export const darkInActive = require("../assets/images/Light Mode_Active.png");
export const notes = require("../assets/images/Notes.png");
export const noteUpload = require("../assets/images/group_Upload Icon.svg");
