import React, { useRef } from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { RouteProps, RouteComponentProps } from "react-router-dom";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Customizable Area End
import * as Yup from "yup";
export const configJSON = require("./config");
import moment from "moment";

export type Props = {
    authToken: any;
    classes: any;
    mode: any;
    setTheme: any;
} & RouteProps &
    RouteComponentProps &
    withAlertBoxProps &
    withToastProps &
    withLoaderProps & {
        id: string;
    };
export interface S {
    // Customizable Area Start
    setPublic: any
    arrayHolder: any;
    allBooks: any;
    myBooks: any;
    notesViewMore: any;
    Loader: boolean;
    allNotes: any;
    authToken: any;
    bookDetailId: any;
    bookDetail: any;
    searchInput: any;
    searchedBooks: any;
    searchedMyBooks: any;
    accountInfo: any;
    ProfileValidationSchema: any;
    order_id: any;
    availablePackages: any;
    myPackages: any;
    toggleMonth: boolean;
    selectedPackage: any;
    viewAll: boolean;
    bestSeller: any;
    // bookSlot: any;
    availableslots: any;
    playBackSpeed: any;
    getMonth: any;
    analytics: any;
    chartValue: any;
    viewNotes: boolean;
    toggleMenu: boolean;
    readSetModal: boolean;
    selectedWeight: any;
    selectedSize: any;
    selectedColor: any;
    changeSetting: any;
    createNotes: any;
    bookReadId: any;
    activeCart: any
    notesAvailable: any;
    editNotes: boolean;
    bookNotes: any;
    bookNoteId: any
    bookFinished: any,
    noteText: any,
    location: any,
    displayChap: boolean,
    chaptersListing: any,
    currentChapterName: any;
    currentChapterNo: any;
    AllNotesForPurchased: any;
    BookNotesForPurchased: any;
    buyNotesUser: any;
    renditionRef: any;
    renditionRefChap: any;
    book: any;
    purchasedNotes: any;
    purchasedNotesPreview: any;
    product_rating: any;
    upload_note: boolean;
    note_id: any;
    is_playing: any;
    returnBookId: any;
    booksChecked: any;
    symbol: any;
    monthly_payment_history: any;
    onetime_payment_history: any;
    subscription_monthly_payment_history: any;
    subscription_onetime_payment_history: any;
    monthlyError: any;
    monthlyOnetimeError: any;
    SubscriptionMonthlyError: any;
    SubscriptionOnetimeError: any;
    selectedCountryError: boolean;
    selectedCountry: any;
    selectedCountryName: any;
    allCurrency: any;
    selectedCountryDefault: any;
    numPages: any;
    pageNumber: any;
    purchaseType: any;
    noteIdForDelete: any
    BookId: any;
    BookAudio: any;
    // Customizable Area End
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
    [x: string]: any;
    getProductApiCallId: any;
    GetAllBooksApiCallId: string = "";
    myBooksApiCallId: string = "";
    bookPaymentHistoryPerMonthApiCallId: string = "";
    getAllNotesApiCallId: string = "";
    getBookDetailApiCallId: string = "";
    postSearchBookApiCallId: string = "";
    putUpdateProfileApiCallId: string = "";
    postCreateCartApiCallId: string = "";
    getActiveCartApiCallId: string = "";
    getBuyPackagesApiCallId: string = "";
    getBestSellerBooksApiCallId: string = "";
    getAnalyticsApiCallId: string = "";
    getEarningsApiCallId: string = "";
    postCreateNotesApiCallId: string = "";
    showNotesApiCallId: string = "";
    getNoteTextApiCallId: string = "";
    putEditNoteTextApiCallId: string = "";
    deleteNoteTextApiCallId: string = "";
    putEditBookNoteApiCallId: string = "";
    deleteBookNoteApiCallId: string = "";
    getAllNotesForPurchasedApiCallId: string = "";
    getBookNotesForPurchasedApiCallId: string = "";
    buynotesUserApiCallId: string = "";
    getpurchasedNotesApiCallId: string = "";
    getpurchasedNotesPreviewApiCallId: string = "";
    postProductReviewApiCallId: string = ""
    lineChart: any;
    postRefundBookApiCallId: string = "";
    getMyPackagesApiCallId: string = "";
    postaddPackageBooksApiCallId: string = "";
    postCancelBookPassApiCallId: string = "";
    bookPaymentHistoryOneTimeApiCallId: string = "";
    subscriptionMonthlyHistoryApiCallId: string = "";
    subscriptionOneTimeHistoryApiCallId: string = "";
    getCountryCurrencyAPICallId: string = "";
    // GetBookSlotApiCallId: string = "";
    getBooksAvailableSlotApiCallId: string = "";
    postBookAudioApiCallId: string = "";
    playerRef: any;

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];

        let ProfileValidationSchema = {
            fullName: Yup.string().required(configJSON.errorNameEmpty),
            email: Yup.string().required(configJSON.errorNameEmpty),
            DOB: Yup.string()
                .required("DOB is Required")
                .test("DOB", "Please choose a valid date of birth", (value) => {
                    return moment().diff(moment(value), "years") >= 0;
                })
            // .email(configJSON.errorEmailValidation)
        };

        this.playerRef  = React.createRef();
        this.state = {
            arrayHolder: [],
            allBooks: "",
            returnBookId: "",
            myBooks: "",
            notesViewMore: true,
            Loader: true,
            allNotes: "",
            authToken: "",
            bookDetailId:
                window.location.pathname ==
                `/Catalogue/BookDetails/${window.location.pathname.split("/")[3]
                }` && window.location.pathname.split("/")[3],
            bookReadId: window.location.pathname ==
                `/book-read/${window.location.pathname.split("/")[2]
                }` && window.location.pathname.split("/")[2],
            bookDetail: "",
            searchInput: null,
            searchedBooks: "",
            searchedMyBooks: "",
            accountInfo: "",
            ProfileValidationSchema: ProfileValidationSchema,
            order_id: "",
            availablePackages: "",
            myPackages: "",
            toggleMonth: true,
            selectedPackage: "",
            viewAll: false,
            bestSeller: "",
            BookId: "",
            // bookSlot: "",
            availableslots: "",
            playBackSpeed: "1",
            getMonth: moment(new Date()),
            analytics: "",
            chartValue: "",
            monthlyError: "",
            monthlyOnetimeError: "",
            SubscriptionMonthlyError: "",
            SubscriptionOnetimeError: "",
            viewNotes: false,
            toggleMenu: false,
            readSetModal: false,
            selectedWeight:
                (this.state?.changeSetting && this.state.changeSetting.fontWeight) ||
                200,
            selectedSize:
                (this.state?.changeSetting && this.state.changeSetting.fontSize) ||
                16,
            selectedColor:
                (this.state?.changeSetting && this.state.changeSetting.backColor) ||
                "#fff",
            changeSetting: "",
            createNotes: false,
            activeCart: "",
            notesAvailable: "",
            editNotes: false,
            bookNotes: "",
            bookNoteId: "",
            note_id: "",
            bookFinished: false,
            noteText: "",
            location: "",
            displayChap: false,
            chaptersListing: "",
            currentChapterName: "",
            currentChapterNo: "",
            AllNotesForPurchased: "",
            BookNotesForPurchased: "",
            buyNotesUser: "",
            renditionRef: React.createRef(),
            renditionRefChap: React.createRef(),
            setPublic: false,
            book: "",
            purchasedNotes: "",
            purchasedNotesPreview: "",
            product_rating: "",
            upload_note: false,
            is_playing: false,
            booksChecked: [],
            monthly_payment_history: [],
            onetime_payment_history: [],
            subscription_monthly_payment_history: [],
            subscription_onetime_payment_history: [],
            symbol: "",
            selectedCountry: null,
            selectedCountryName: null,
            selectedCountryError: false,
            allCurrency: [],
            selectedCountryDefault: null,
            numPages: null,
            pageNumber: 1,
            purchaseType: "",
            noteIdForDelete: "",
            BookAudio: "",
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    componentDidMount = async () => {
        const authToken = await StorageProvider.get("authToken");
        const search = await StorageProvider.get("search");
        const accountInfo = await StorageProvider.get("accountInfo");
        const orderId = await StorageProvider.get("orderId");
        const customize = await StorageProvider.get("customize");
        const localData = await localStorage.getItem("symbol");
        const location = window.location.pathname;
        const number_book = localStorage.setItem("number_book", "5");



        if (authToken) {
            if (customize) {
                this.setState({
                    changeSetting: JSON.parse(customize),
                });
            }
            this.setState({
                accountInfo: JSON.parse(accountInfo),
            });

            this.setState({
                symbol: localData
            });

            // this.setState({
            //     BookId: this.props.match.params.id
            //   })
            this.setState({ authToken }, () => {

                this.getActiveCart();
                this.gettingOrderID();
                //   this.postBookAudio(e);

                location.split('/')[1] === "Rating" && this.getbuynotesUser();
                location === "/purchasedNotes" && this.getpurchasedNotes();
                location.split("/")[1] == "purchasedNotePreview" && this.getpurchasedNotesPreview();
                // location === "/Catalogue/Home" && this.getAllNotesForPurchased();
                // location == "/buyNotes" && this.getAllNotesForPurchased();
                location == "/Catalogue/Home" && this.getAllBooks();
                location == "/view-allBooks" && this.getAllBooks();
                location == `/Catalogue/BookDetails/${location.split("/")[3]}` && this.getAllBooks();
                location == `/Catalogue/BookDetails/${location.split("/")[3]}` && this.getBooksAvailableSlot();
                location == "/Catalogue/MyBooks" && (this.myBooks(), this.getMonthlyPaymentHistory(), this.getOneTimePaymentHistory());
                location == "/Catalogue/PaymentHistory" && (this.getMonthlyPaymentHistory(), this.getOneTimePaymentHistory(), this.getSubscriptionMonthlyPaymentHistory(), this.getSubscriptionOnetimePaymentHistory());
                location.split("/")[1] == "booknotes" && this.getNoteText(location.split("/")[2]);
                location == "/view-allBooks" && this.getBestSellerBooks();
                location == "/user-profile" && this.getAllCountryCurrency();
                location.split("/")[1] == "package-books" && this.getAllBooks();
                location.split("/")[1] == "package-books" && this.getBestSellerBooks();
                location.split("/")[1] == "package-books" && (
                    toast.warning(`Please Select Any ${location.split("/")[2]} Books`)
                )
                location == "/Catalogue/MyNotes" && this.getAllNotes();
                location == `/Catalogue/BookDetails/${location.split("/")[3]}` && this.getBookDetail();
                location == `/book-read/${location.split("/")[2]}` && (this.getBookDetail(), this.getShowNotes(`${location.split("/")[2]}`))

                location.split("/")[1] == `viewMynotes` && this.getShowNotes(location.split("/")[2])
                location.split("/")[1] == `purchasedNoteView` && this.getbuynotesUser()

                location == `/Catalogue/MyEarnings` &&
                    (this.getAnalytics(), this.postEarnings(this.state.getMonth),
                        this.createChart());
                location.split("/")[1] == `buyNotesPreview` && this.getBookNotesForPurchased()
                location.split("/")[1] == `buyNotesPurchase` && this.getbuynotesUser()
                location == "/BuyPackages" &&
                    // JSON.parse(accountInfo).attributes.have_package &&
                    this.getBuyPackages();
                location == "/MyPackages" && (this.getMyPackages(), this.getSubscriptionMonthlyPaymentHistory(), this.getSubscriptionOnetimePaymentHistory())
                location == "/search-books" &&
                    (search ? this.postSearchBook(search) : this.getAllBooks(),
                        this.setState({
                            searchInput: search,
                        }));
                //@ts-ignore
                // this.createChart();
            });
        } else {
            const { history }: any = this.props;
            history.push("/EmailAccountLoginBlock");
        }
    };

    componentDidUpdate = async (prevProps: Props, prevState: S) => {
        const search = await StorageProvider.get("search");
        const accountInfo = await StorageProvider.get("accountInfo");
        const location = window.location.pathname

        if (prevState.authToken !== this.state.authToken) {
            window.location.pathname == "/Catalogue/Home/" && this.getAllBooks();
            window.location.pathname == "/Catalogue/MyBooks/" && this.myBooks();
            window.location.pathname == "/Catalogue/MyNotes/" && this.getAllNotes();
        }

        if (
            prevState.bookDetailId &&
            prevState.bookDetailId != window.location.pathname.split("/")[3]
        ) {
            this.setState({
                bookDetailId: window.location.pathname.split("/")[3],
            });
            setTimeout(() => {
                this.getBookDetail();
            }, 1000);
        }
        if (prevState.searchInput != null && prevState.searchInput != search) {
            this.setState({
                searchInput: search,
            });
            this.state.searchInput ? this.postSearchBook(search) : this.getAllBooks()
        }
        if (location.split("/")[1] == "package-books" && prevState.booksChecked != this.state.booksChecked) {
            this.state.booksChecked.length == location.split("/")[2] && this.handleReadSetModalClose()
            this.state.booksChecked.length > location.split("/")[2] && toast.error(`You cannot select more than ${location.split("/")[2]} books`)
        }
        if (prevState.chaptersListing !== (this.state.chaptersListing && this.state.chaptersListing)) {
            this.state.chaptersListing && this.settingLocation(this.state.chaptersListing[0])
        }
        if (prevState.changeSetting != this.state.changeSetting) {
            if (this.state.renditionRef.current) {
                const color = `${this.state.changeSetting &&
                    (this.state.changeSetting.selectedColor == "#000000" ? "#fafafa" : "")}`
                this.state.changeSetting.selectedColor == "#000000", this.state.changeSetting && this.state.changeSetting.selectedColor
                this.state.renditionRef.current.themes.fontSize(`${this.state.changeSetting.fontSize}px`)
                this.state.renditionRef.current.themes.register('custom', {
                    body: {
                        'font-size': `${this.state.changeSetting.fontSize}px`,
                        'font-weight': `${this.state.changeSetting.fontWeight}`,
                        'background-color': `${this.state.changeSetting.backColor} !important`,
                        'color': `${color}`
                    }
                })
                this.state.renditionRef.current.themes.select('custom')
            }
        }

        if (prevState.getMonth != this.state.getMonth) {
            this.createChart();
        }
    };

    onDocumentLoadSuccess(numPages: any) {
        this.setState({ numPages: numPages })
    }

    async receive(from: string, message: Message) {

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors) {
                const apiRequestCallId = message.getData(
                    getName(MessageEnum.RestAPIResponceDataMessage)
                );


                if (apiRequestCallId != null) {
                    // get all books
                    if (apiRequestCallId === this.GetAllBooksApiCallId) {

                        console.log(responseJson, "=====@@@=")

                        window.location.pathname == "/search-books" && this.filterSearchedBooks(responseJson.data)
                        const notes = responseJson.data.filter((x: any) => {
                            return x.attributes.note_count > 0
                        })
                        this.setState({
                            allBooks: responseJson.data,
                            notesAvailable: notes,
                            Loader: false,
                        });
                    }

                    //best seller
                    if (apiRequestCallId === this.getBestSellerBooksApiCallId) {
                        this.setState({ bestSeller: responseJson.data, Loader: false, });
                    }

                    // get_available_slots
                    if (apiRequestCallId === this.getBooksAvailableSlotApiCallId) {
                        console.log("===availableslots@@@@", responseJson)
                        this.setState({ availableslots: responseJson.data, Loader: false, });
                    }


                    // get my books
                    if (apiRequestCallId === this.myBooksApiCallId) {
                        if (responseJson && responseJson.data) {
                            this.setState({ myBooks: responseJson.data, Loader: false, });
                        } else {
                            toast.error(responseJson?.message);
                        }
                    }

                    // Monthly Book Payment History
                    if (apiRequestCallId === this.bookPaymentHistoryPerMonthApiCallId) {
                        console.log(responseJson.data, "===@@@")

                        if (responseJson && responseJson.data) {
                            this.setState({ monthly_payment_history: responseJson.data, Loader: false, });
                        } else {
                            this.setState({ monthlyError: responseJson?.message })
                        }
                    }

                    // One Time Book Payment History
                    if (apiRequestCallId === this.bookPaymentHistoryOneTimeApiCallId) {
                        if (responseJson && responseJson.data) {
                            this.setState({ onetime_payment_history: responseJson.data, Loader: false, });
                        } else {
                            this.setState({ monthlyOnetimeError: responseJson?.message })
                        }
                    }



                    //Get Subscription Monthly Payment History
                    if (apiRequestCallId === this.subscriptionMonthlyHistoryApiCallId) {
                        console.log("@@@@@@=======", responseJson?.subscription_payment_history?.data)

                        if (responseJson && responseJson?.subscription_payment_history?.data) {
                            this.setState({
                                subscription_monthly_payment_history: responseJson?.subscription_payment_history?.data,
                                Loader: false,
                            });
                        } else { this.setState({ SubscriptionMonthlyError: responseJson?.message }) }
                    }

                    // Get Currency
                    if (apiRequestCallId === this.getCountryCurrencyAPICallId) {
                        let code = localStorage.getItem("currency_code");
                        if (responseJson && responseJson?.data) {

                            let index = responseJson.data.findIndex((item: any) => item.currency_code === code);

                            this.setState({
                                allCurrency: responseJson.data,
                                selectedCountryDefault: index >= 0 ? responseJson.data[index] : null
                            })
                        } else { toast.error("Something wrong"); }
                    }

                    //Get Subscription Onetime Payment History
                    if (apiRequestCallId === this.subscriptionOneTimeHistoryApiCallId) {
                        if (responseJson && responseJson?.subscription_payment_history?.data) {
                            this.setState({
                                subscription_onetime_payment_history: responseJson?.subscription_payment_history?.data,
                                Loader: false,
                            });
                        } else {
                            this.setState({
                                SubscriptionOnetimeError: responseJson?.message
                            })
                        }
                    }

                    // get all notes
                    if (apiRequestCallId === this.getAllNotesApiCallId) {
                        this.setState({
                            allNotes: responseJson.data,
                            Loader: false,
                        });
                    }
                    // post book audio
                    // if (apiRequestCallId === this.postBookAudioApiCallId) {
                    //     console.log(responseJson.data,"@@@@======")

                    //     this.setState({
                    //         BookAudio: responseJson.data,
                    //         Loader: false,
                    //     });
                    // }

                    // get book detail
                    if (apiRequestCallId === this.getBookDetailApiCallId) {
                        this.setState({
                            bookDetail: responseJson.data,
                            Loader: false,
                        });
                        StorageProvider.set("shared_price", responseJson?.data?.attributes?.shared_price);
                        StorageProvider.set("no_of_split", responseJson?.data?.attributes?.no_of_split);
                    }

                    // search books
                    if (apiRequestCallId === this.postSearchBookApiCallId) {
                        this.filterSearchedBooks(responseJson.data);
                        this.setState({ Loader: false, });
                    }

                    //update profile
                    if (apiRequestCallId === this.putUpdateProfileApiCallId) {
                        toast.success(responseJson?.user?.meta?.message);
                        this.settingProfileInfo(responseJson?.user?.data);
                        if (this.state.selectedCountry == "USD") {
                            localStorage.setItem("symbol", "$");
                            localStorage.setItem("currency_code", "USD");
                        } else if (this.state.selectedCountry == "INR") {
                            localStorage.setItem("symbol", "₹");
                            localStorage.setItem("currency_code", "INR");
                        } else if (this.state.selectedCountry == "CAD") {
                            localStorage.setItem("symbol", "CA$");
                            localStorage.setItem("currency_code", "CAD");
                        } else if (this.state.selectedCountry == "GBP") {
                            localStorage.setItem("symbol", "£");
                            localStorage.setItem("currency_code", "GBP");
                        }
                        this.setState({ Loader: false, accountInfo: responseJson?.user?.data, });
                        this.props.history.push('/Catalogue/Home')
                    }

                    //active cart
                    if (apiRequestCallId === this.getActiveCartApiCallId) {
                        if (responseJson.data) {
                            this.settingOrderedId(responseJson.data.order.data.id, responseJson.data.order?.data?.attributes?.order_items?.length);
                            this.setState({
                                Loader: false,
                                activeCart: responseJson.data.order?.data?.attributes?.order_items?.length
                            });
                            console.log(responseJson.data?.order?.data, "===@@@")
                            StorageProvider.set("cartItem", responseJson.data?.order?.data)
                        }
                        this.setState({ Loader: false })
                    }

                    //get buy packages
                    if (apiRequestCallId === this.getBuyPackagesApiCallId) {
                        console.log(responseJson.data, "===@@@")
                        this.setState({
                            Loader: false,
                            availablePackages: responseJson.data,
                            selectedPackage: responseJson.data[0].id,
                        });
                    }

                    //create cart
                    if (apiRequestCallId === this.postCreateCartApiCallId) {
                        this.setState({ Loader: false, });
                        this.getBookDetail();
                        toast.success(`${this.state.note_id ? "Note" : "Book"} Successfully Added`);
                        this.state.order_id != responseJson.data.order.data.id &&
                            this.settingOrderedId(responseJson.data.order.data.id, responseJson.data.order?.data?.attributes?.order_items?.length);
                    }

                    // get analytics
                    if (apiRequestCallId === this.getAnalyticsApiCallId) {
                        this.setState({ analytics: responseJson.data, Loader: false, });
                    }

                    // post earnings
                    if (apiRequestCallId === this.getEarningsApiCallId) {
                        let value: any = [];
                        responseJson.data.monthly_week_wise.totalearnings.map(
                            (data: any) => {
                                value.push(data.value);
                            }
                        );
                        this.setState({ Loader: false, chartValue: value, });
                    }

                    // show notes
                    if (apiRequestCallId === this.showNotesApiCallId) {
                        this.setState({
                            Loader: false,
                            book: responseJson?.data?.attributes,
                            bookNotes: responseJson?.data?.attributes?.notes,
                            setPublic: responseJson?.data?.attributes?.notes?.length > 0 && responseJson?.data?.attributes?.notes[0]?.attributes?.set_public || false,
                            bookNoteId: responseJson?.data?.attributes?.notes?.length > 0 && responseJson?.data?.attributes?.notes[0]?.id
                        });
                    }

                    // post create notes
                    if (apiRequestCallId === this.postCreateNotesApiCallId) {
                        if (responseJson.data) {
                            this.setState({ createNotes: false })
                            this.handleuploadNoteClose();
                            toast.success("Note Successfully Added")
                            this.getShowNotes(window.location.pathname.split("/")[2])
                        }
                        this.setState({ Loader: false, });
                    }
                    // post book Audio
                    if (apiRequestCallId === this.postBookAudioApiCallId) {
                        console.log(responseJson.data, "@@===")
                        if (responseJson && responseJson.data) {
                            this.setState({ BookAudio: responseJson.data });
                        } else {
                            console.log('@@@ Audio Ref ====', this.playerRef.current.audio);
                            this.playerRef.current.audio.current.pause();
                        } 


                    }

                    // show note text
                    if (apiRequestCallId === this.getNoteTextApiCallId) {
                        if (responseJson.data) {
                            this.setState({ noteText: responseJson.data[0] });
                        }
                        if (responseJson.message) {
                            this.setState({ noteText: false })
                        }
                        this.setState({ Loader: false });
                    }

                    //edit note_text
                    if (apiRequestCallId === this.putEditNoteTextApiCallId) {
                        if (responseJson.data) {
                            window.location.pathname.split("/")[1] == "booknotes" && this.getNoteText(window.location.pathname.split("/")[2])
                            window.location.pathname.split("/")[1] == "viewMynotes" && this.getShowNotes(window.location.pathname.split("/")[2])
                            toast.success("Notes Have Been Updated Successfully")
                            this.setState({ createNotes: false })
                        }
                        if (responseJson.message) { toast.error(responseJson.message) }
                        this.setState({ Loader: false, });
                    }

                    // delete note_text
                    if (apiRequestCallId === this.deleteNoteTextApiCallId) {
                        this.setState({
                            Loader: false,
                            createNotes: false
                        });
                        toast.success(responseJson.message)
                        window.location.pathname.split("/")[1] == "booknotes" && this.getNoteText(window.location.pathname.split("/")[2])
                        window.location.pathname.split("/")[1] == "viewMynotes" && this.getShowNotes(window.location.pathname.split("/")[2])
                    }

                    // edit book note
                    if (apiRequestCallId === this.putEditBookNoteApiCallId) {
                        this.setState({
                            Loader: false,
                            editNotes: false
                        });
                        toast.success("Notes Edited Successfully")
                        this.getShowNotes(window.location.pathname.split("/")[2])
                    }

                    // delete book note
                    if (apiRequestCallId === this.deleteBookNoteApiCallId) {
                        this.setState({
                            Loader: false,
                            editNotes: false
                        });
                        toast.success(responseJson.message)
                        this.getShowNotes(window.location.pathname.split("/")[2])
                    }

                    // all notes for purchased
                    if (apiRequestCallId === this.getAllNotesForPurchasedApiCallId) {
                        this.setState({
                            Loader: false,
                            AllNotesForPurchased: responseJson.data
                        });
                    }

                    // book notes for purchased
                    if (apiRequestCallId === this.getBookNotesForPurchasedApiCallId) {
                        this.setState({
                            Loader: false,
                            BookNotesForPurchased: responseJson.data
                        });
                    }

                    // buy notes for user
                    if (apiRequestCallId === this.buynotesUserApiCallId) {
                        this.setState({
                            Loader: false,
                            buyNotesUser: responseJson.data
                        });
                    }

                    // purchased notes
                    if (apiRequestCallId === this.getpurchasedNotesApiCallId) {
                        this.setState({
                            Loader: false,
                            purchasedNotes: responseJson.data
                        });
                    }

                    // purchasednotes preview
                    if (apiRequestCallId === this.getpurchasedNotesPreviewApiCallId) {
                        this.setState({
                            Loader: false,
                            purchasedNotesPreview: responseJson.data
                        });
                    }

                    // product rating
                    if (apiRequestCallId === this.postProductReviewApiCallId) {
                        toast.success("Review Successfully Added")
                        this.setState({ Loader: false, });
                        this.props.history.push('/Catalogue/Home')
                    }

                    // refund book          
                    if (apiRequestCallId === this.postRefundBookApiCallId) {
                        1
                        this.setState({ Loader: false, });
                        this.handleReadSetModalClose()
                        this.myBooks()
                        toast.success(responseJson.message)
                    }

                    // my Packages
                    if (apiRequestCallId === this.getMyPackagesApiCallId) {
                        if (responseJson?.data) {
                            this.setState({ myPackages: responseJson.data, Loader: false })
                        } else {
                            toast.error(responseJson.message)
                        }
                        console.log(responseJson.data, "===@@@")
                    }

                    // add Packages books
                    if (apiRequestCallId === this.postaddPackageBooksApiCallId) {
                        if (responseJson.data) { toast.success("Books Successfully Added To the Package") }
                        this.handleReadSetModalClose()
                        this.setState({ Loader: false, });
                        //@ts-ignore
                        this.props.history.push("/order-placed/package");
                        // this.props.history.push("/MyPackages")
                    }

                    // cancel book pass
                    if (apiRequestCallId === this.postCancelBookPassApiCallId) {
                        toast.success(responseJson.message)
                        this.handleReadSetModalClose()
                        this.setState({
                            Loader: false,
                            myPackages: []
                        });
                        const updatePackage = this.state.accountInfo;
                        updatePackage.attributes.have_package = false;
                        this.setState({ accountInfo: updatePackage, });
                        StorageProvider.set("accountInfo", JSON.stringify(updatePackage));
                        this.getMyPackages();
                    }

                }
            } else {
                StorageProvider.remove("authToken");
                // this.sessionLogout();
            }
        }
    }

    // for session expire
    sessionLogout = async () => {
        const accountInfo = await StorageProvider.get("accountInfo");

        this.props
            .showAlert({
                title: "Session Expired",
                message: "Please Login again!",
            })
            .then((data: any) => {
                StorageProvider.remove("authToken");
                StorageProvider.remove("orderId");
                StorageProvider.remove("accountInfo");
            })
            .then(() => {
                StorageProvider.remove("orderId");
            })
            .then(() => {
                const { history } = this.props;
                history.push("/");
            });
    };

    // get all books
    getAllBooks = (): boolean => {
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.GetAllBooksApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.GetAllBooksAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // get_available_slots
    getBooksAvailableSlot = async (): Promise<boolean> => {
        const headers = {
            "content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const availableBook = await localStorage.getItem("boookid")

        console.log("@@@=hh===", availableBook)

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBooksAvailableSlotApiCallId = requestMessage.messageId

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getBooksAvailableSlotApiEndPoint + `/${availableBook}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;

    };


    // get BestSellerBooks
    getBestSellerBooks = (): boolean => {
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getBestSellerBooksApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getBestSellerBooksAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //get my books
    myBooks = (): boolean => {
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.myBooksApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.GetMyBooksAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Get Monthly Book Payment History
    getMonthlyPaymentHistory = (): boolean => {
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.bookPaymentHistoryPerMonthApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.bookPaymentHistoryPerMonthAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    //Get One Time Book Payment History
    getOneTimePaymentHistory = (): boolean => {
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.bookPaymentHistoryOneTimeApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.bookPaymentHistoryOneTimeAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    //Get Monthly Subscription Payment History
    getSubscriptionMonthlyPaymentHistory = (): boolean => {
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.subscriptionMonthlyHistoryApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.subscriptionPaymentHistoryMonthAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    //Get One Time Subscription Payment History
    getSubscriptionOnetimePaymentHistory = (): boolean => {
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.subscriptionOneTimeHistoryApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.subscriptionOnetimePaymentHistoryAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    // get all notes
    getAllNotes = (): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllNotesApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllNotesAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // get book details
    getBookDetail = (): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getBookDetailApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.GetAllBooksAPiEndPoint + `/${this.state.bookDetailId || this.state.bookReadId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };
    // // post book audio
    // getBookAudio = (): boolean => {

    //     const headers = {
    //         "Content-Type": configJSON.productApiContentType,
    //         token: this.state.authToken,
    //     };

    //     const requestMessage = new Message(
    //         getName(MessageEnum.RestAPIRequestMessage)
    //     );

    //     this.getBookAudioApiCallId = requestMessage.messageId;
    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIResponceEndPointMessage),
    //         configJSON.getChapterWiseAudioApiEndPoint
    //     );
    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestHeaderMessage),
    //         JSON.stringify(headers)
    //     );
    //     requestMessage.addData(
    //         getName(MessageEnum.RestAPIRequestMethodMessage),
    //         configJSON.apiMethodTypeGet
    //     );
    //     runEngine.sendMessage(requestMessage.id, requestMessage);
    //     return true;
    // };

    //search book
    postSearchBook = (search: any): boolean => {
        // const product = this.state.productToBeAdded
        // this.myBooks();
        console.log("@@@ search", search);

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };
        const httpBody = {
            search_string: search,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postSearchBookApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postSearchBookAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    // updtae profile
    putUpdateProfile = (values: any): boolean => {
        // var today = new Date();
        // var year = today.getUTCFullYear()
        // var valuesDOB = values.DOB.getUTCFullYear()
        // console.log("@@@",year);   
        console.log("@@@", values)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };
        const httpBody = {
            data: {
                type: this.state.accountInfo.type,
                attributes: this.state.accountInfo.type === "social_account" ? {
                    full_name: values.fullName,
                    email: values.email,
                    dob: values.DOB,
                    major: values.major,
                    college: values.college,
                    state_n_city: values.state,
                    country_code: this.state.selectedCountryName,
                    currency_code: this.state.selectedCountry
                } :
                    {
                        full_name: values.fullName,
                        email: values.email,
                        dob: values.DOB,
                        major: values.major,
                        college: values.college,
                        state_n_city: values.state,
                        country_name: this.state.selectedCountryName,
                        currency_code: this.state.selectedCountry
                    }
            },
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.putUpdateProfileApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.putUpdateProfileAPiEndPoint + `${this.state.accountInfo.id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePut
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //screate cart
    postCreateCart = ({ ...paylod }): boolean => {

        this.setState({ Loader: true })

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const httpBody = {
            book_id: paylod.ID,
            quantity: "1",
            purchase_type: paylod.TYPE
        };

        this.gettingOrderID();

        setTimeout(() => {

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.postCreateCartApiCallId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.postCreateCartAPiEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.apiMethodTypePost
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }, 2000);
        return true;
    };

    // get active cart
    getActiveCart = (): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getActiveCartApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getActiveCartAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // BuyPackages
    getBuyPackages = (): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBuyPackagesApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getBuyPackagesAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    //my packages
    getMyPackages = (): boolean => {
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getMyPackagesApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getMyPackagesAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // get analytics
    getAnalytics = (): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAnalyticsApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAnalyticsAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // post earnings
    postEarnings = (date: any): boolean => {
        const year = date.format("YYYY");
        const month = date.format("M");
        const days = moment(date).daysInMonth();

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        setTimeout(() => {
            const httpBody = {
                start_date: `${year}-${month}-01`,
                end_date: `${year}-${month}-${days}`,
            };

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.getEarningsApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.getEarningsAPiEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.apiMethodTypePost
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);
        }, 1000);
        return true;
    };



    //create book Audio
    postBookAudio = (values: any): boolean => {

        console.log("@@val", values)

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };
        const bookno = localStorage.getItem("MyNoteBookNo")

        const httpBody = {
            "data": {
                "book_id": bookno,
                "chapter_no": values > 1 ? values : "1",
            }
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.postBookAudioApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postChapterWiseAudioApiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };



    //create book notes
    postCreateNotes = (values: any): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };
        const httpBody = {
            "note": {
                "note_id": this.state.bookNoteId ? this.state.bookNoteId : "",
                "title": values.title,
                "book_id": window.location.pathname.split("/")[2],
                "price": "",
                "chapter_no": this.state.currentChapterNo && this.state.currentChapterNo,
                "chapter_name": this.state.currentChapterName && this.state.currentChapterName,
                "time_from": "02:00",
                "note_data": values.note_data
            }
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.postCreateNotesApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postCreateNotesAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };


    //show notes
    getShowNotes = (id: any): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.showNotesApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postCreateNotesAPiEndPoint + `/${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // get note_text
    getNoteText = (id: any): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getNoteTextApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getNoteTextAPiEndPoint + `/${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //edit notes_text  
    putEditNoteText = (values: any, id: any): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const httpBody = {
            "id": id,
            "note_data": values.note_data,
            "note_title": values.title
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.putEditNoteTextApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.putEditNoteTextAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePut
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Delete note text
    deleteNoteText = (id: any): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteNoteTextApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.deleteNoteTextAPiEndPoint + `/${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeDelete
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Edit book note
    putEditBookNote = (values: any): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const httpBody = {
            "id": this.state.bookNotes && this.state.bookNotes[0].id,
            "set_public": values.note_ForSale,
            "price": values.price
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.putEditBookNoteApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.putEditBookNoteAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePut
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //Delete book note
    deleteBookNote = (id: any): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteBookNoteApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postCreateNotesAPiEndPoint + `/${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeDelete
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //all notes for purchased
    getAllNotesForPurchased = (): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAllNotesForPurchasedApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getAllNotesForPurchasedAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // book notes for purchased
    getBookNotesForPurchased = (): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getBookNotesForPurchasedApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getBookNotesForPurchasedAPiEndPoint + `/${window.location.pathname.split("/")[2]}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //buynotesUser 
    getbuynotesUser = (): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.buynotesUserApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.buynotesUserAPiEndPoint + `/${window.location.pathname.split("/")[2]}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //pucheased notes
    getpurchasedNotes = (): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getpurchasedNotesApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getpurchasedNotesAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //purchased notes preview
    getpurchasedNotesPreview = (): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getpurchasedNotesPreviewApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getpurchasedNotesAPiEndPoint + `/${window.location.pathname.split('/')[2]}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    settingBookId = async (id: any) => {
        await StorageProvider.set("bookId", id);
        this.setState({ BookId: id })
    };

    bookPurchaseType = async (type: any) => {
        this.setState({ purchaseType: type })
        console.log(this.state.purchaseType, "===@@@")
    }

    settingProfileInfo = async (data: any) => {
        await StorageProvider.set("accountInfo", JSON.stringify(data));
    };

    gettingOrderID = async () => {
        const order_id = await StorageProvider.get("orderId");
        this.setState({
            order_id: order_id,
        });
    };
    settingOrderedId = async (id: any, length: any) => {
        StorageProvider.set("orderId", id);
        StorageProvider.set("cartLength", length);
    };

    filterSearchedBooks = (data: any) => {
        const filteredSearchBooks = data.filter((x: any) => {
            return x.attributes.is_purchased == false;
        });
        const filteredMySearchBooks = data.filter((x: any) => {
            return x.attributes.is_purchased == true;
        });
        this.setState({
            searchedBooks: filteredSearchBooks,
            searchedMyBooks: filteredMySearchBooks
        });
    };

    handleReadSetModalClose = () => {
        this.setState({
            readSetModal: !this.state.readSetModal,
        });
    };
    handleCustomize = async (customize: any) => {
        this.setState({
            changeSetting: customize,
        });
        await StorageProvider.set("customize", JSON.stringify(customize));
    };

    //post review
    postProductReview = (rating: any): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const httpBody = {
            "review": {
                "note_id": window.location.pathname.split('/')[2],
                "rating": rating,
                "comment": ""
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.postProductReviewApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postProductReviewAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // add subscribed books
    postaddPackageBooks = (): boolean => {
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        function useQuery() {
            return new URLSearchParams(window.location.search);
        }
        let query = useQuery();

        let books_id: any = [];

        this.state.booksChecked.forEach((book: any) => { books_id.push(book.name) })

        const httpBody = {
            "order_id": query.get("order_id"),
            "book_ids": books_id
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.postaddPackageBooksApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postaddPackageBooksAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // book pass cancelled
    postBookPassCancel = (order_id: any, package_id: any): boolean => {
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const httpBody = {
            "data": {
                "attributes": {
                    "order_id": order_id,
                    "package_id": package_id
                }
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.postCancelBookPassApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postCancelPassAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    //refund book
    postRefundBook = (bookId: any, order_id: any): boolean => {

        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };
        const httpBody = {
            "data": {
                "attributes": {
                    "order_id": order_id,
                    "book_id": bookId
                }
            }
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.postRefundBookApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postRefundBookAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypePost
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    handleCreateNotesClose = () => {
        this.setState({ createNotes: !this.state.createNotes, });
    };
    handleuploadNoteClose = () => {
        this.setState({
            upload_note: !this.state.upload_note,

        });
    };
    handleeditNotesClose = () => {
        this.setState({ editNotes: !this.state.editNotes, });
    }

    bookFinishedClose = () => {
        this.setState({
            bookFinished: !this.state.bookFinished,
        });
    }
    settingLocation = (x: any) => {
        this.setState({
            location: x.href,
            currentChapterName: x.label,
            currentChapterNo: 1
        })
    }
    chapterChanged = (e: any) => {
        let chapterNo;
        if (this.state.renditionRef.current) {
            const currentChap = this.state.chaptersListing.filter((chap: any, index: any) => {
                if (chap.href == this.state.renditionRef.current.location.start.href) {
                    chapterNo = index + 1
                    return chap.href == this.state.renditionRef.current.location.start.href
                }
            })[0]
            if (currentChap) {
                this.setState({
                    currentChapterName: currentChap && currentChap.label,
                    currentChapterNo: chapterNo && chapterNo
                })
            }
        }
    }

    getAllCountryCurrency = () => {
        const headers = {
            "Content-Type": configJSON.productApiContentType, token: this.state.authToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCountryCurrencyAPICallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCountryCurrencyEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }

    currencyHandleChange = (e: any, value: any) => {
        if (value != null) {
            this.setState({
                selectedCountryError: false
            })
            this.setState({
                selectedCountryName: value.name,
                selectedCountry: value.currency_code,
                selectedCountryDefault: value
            });
        } else {
            this.setState({
                selectedCountryError: true
            })
            this.setState({
                selectedCountry: null,
                selectedCountryName: null,
                selectedCountryDefault: null
            });
        }
    }
}

function e(e: any, any: any) {
    throw new Error("Function not implemented.");
}
