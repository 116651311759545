// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { toast } from "react-toastify";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
}

interface S {
  // Customizable Area Start
  countryName: any;
  countryCurrencyName: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class currencyController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCurrencyAPICallId: any
  postCurrencyAPICallId: any
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];
    this.state = {
      countryName: [],
      countryCurrencyName: ""
    };
    // Customizable Area End

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    console.log("@@@ props", this.props);
    { this.getCurrency() }
    // Customizable Area End
  }

  handlechange = (item: any) => {
    console.log("@@@ event", item);
    this.setState({
      countryCurrencyName: item
    })
  }

  // Customizable Area End
  async receive(form: string, message: Message) {
    console.log("@@@ API MESSAGE LOGIN =================", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && responseJson?.data) {
        if (apiRequestCallId === this.getCurrencyAPICallId) {
          console.log("@@@123", responseJson);
          this.setState({
            countryName: responseJson.data
          }, () => {
            console.log("@@@ set state", this.state.countryName);
          })

        }
        if (apiRequestCallId === this.postCurrencyAPICallId) {
          toast.success(responseJson.success)
          console.log("@@@", responseJson);
          
            if (responseJson?.data?.currency_code == "USD") {
                localStorage.setItem("symbol", "$");
                localStorage.setItem("currency_code", "USD");
            } else if (responseJson?.data?.currency_code == "INR") {
                localStorage.setItem("symbol", "₹");
                localStorage.setItem("currency_code", "INR");
            } else if (responseJson?.data?.currency_code == "CAD") {
                localStorage.setItem("symbol", "CA$");
                localStorage.setItem("currency_code", "CAD");
            } else if (responseJson?.data?.currency_code == "GBP") {
                localStorage.setItem("symbol", "£");
                localStorage.setItem("currency_code", "GBP");
            
              }
              this.props.history.push("/Catalogue/Home");
        }
      }
      if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.getCurrencyAPICallId) {

        }
        if (apiRequestCallId === this.postCurrencyAPICallId) {

        }
      }
    } else {
      this.parseApiCatchErrorResponse(errorReponse);
    }
    // Customizable Area End
  }



  //==============API Function Call==================
  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = (await localStorage.getItem("token")) || "";
    const header = {
      "Content-Type": contentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };
  getCurrency = async () => {
    this.getCurrencyAPICallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.getCountryCurrencyEndPoint,
    });
  }
  postCurrency = async () => {
    let userid = (await localStorage.getItem("id"))
    console.log("@@@ acount", userid);

    let data = {
      id: userid,
      country_name: this.state.countryCurrencyName,
    }
    this.postCurrencyAPICallId = await this.apiCall({
      contentType: configJSON.productApiContentType,
      method: configJSON.apiMethodTypePost,
      endPoint: configJSON.postCountryCurrencyEndPoint,
      body: data,
    });
  }

}
// Customizable Area End