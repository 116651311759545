import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {getName} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { RouteComponentProps } from "react-router";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { toast } from "react-toastify";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
// Customizable Area End

export const configJSON = require("./config");

export type Props = RouteComponentProps & {
    authToken: any;
    logout: any;
    accountInfo: any;
    mode: boolean;
    // Customizable Area Start
    // Customizable Area End
} & withAlertBoxProps &
    withToastProps &
    withLoaderProps & {
        id: string;
        // Customizable Area Start
        // Customizable Area End
    };

interface S {
    // Customizable Area Start
    getMonth: any;
    getReturnStatus: any;
    geBoughtAs: any;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class OrdersListController extends BlockComponent<
    Props,
    S,
    SS
> {
    
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AlertMessage),
        ];

        this.state = {
            getMonth: "",
            geBoughtAs: "",
            getReturnStatus: "",
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        runEngine.debugLog("Message Recived", message);
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );
        }
        // Customizable Area End
    }

    // Customizable Area Start

    handleChangeMonth = (event:any) =>{
        this.setState({
            getMonth: event.target.value
        })
    }
    handleChangeBoughtAs = (event:any) =>{
        this.setState({
            geBoughtAs: event.target.value
        })
    }
    handleChangeReturnStatus = (event:any) =>{
        this.setState({
            getReturnStatus: event.target.value
        })
    }


   
    // Customizable Area End
}
