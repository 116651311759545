import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";


import { runEngine } from "../../../framework/src/RunEngine";
import { date } from "yup";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  params: any;
  match: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  getUserDetail: any;
  isUserLoggedIn: any;
  qrUserId: any;

  // Customizable Area End
}


interface SS {
  id: any;
}

export default class QrCodeDetailController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUserDetailAPICallID: any;

  _unsubscribe: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
    ];

    this.state = {
      //================= Customizable Area Start =================//
      getUserDetail: '',
      isUserLoggedIn: false,
      qrUserId: "",

      //================= Customizable Area End ======================//
    };
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
     this.getUserDetail()
    this.checkUserStatus();

    this.setState({
      qrUserId: this.props.match.params.id
    })
  }

  checkUserStatus = async () => {
    let token = await localStorage.getItem("token");
    if (token) {
      this.setState({ isUserLoggedIn: true }, () => {
        if (this.state.isUserLoggedIn) {
          this.getUserDetail();

        }
      });
    }
  };


  async componentWillUnmount() {
    super.componentWillUnmount();

    // Customizable Area Start
    // Customizable Area Start
  }

  //================ Customizable Area Start ========================//

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.data) {
        if (apiRequestCallId === this.getUserDetailAPICallID) {
          this.getUserDetailSuccessCallBack(responseJson);
        }

      } else if (responseJson && responseJson.errors) {
        if (apiRequestCallId === this.getUserDetailAPICallID) {
          this.getUserDetailFailureCallBack(responseJson);
        }

      }
     
    }
  }

  //================ Customizable Area End ========================//



  //================ Customizable Area Start ========================//

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;
    const token = (await localStorage.getItem("token")) || "";
    const header = {
      "Content-Type": contentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );

    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),

        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };


  //================ Customizable Area End ========================//


  //================ Customizable Area Start ========================//

  getUserDetail = async () => {
    this.getUserDetailAPICallID = await this.apiCall({
      contentType: configJSON.ApiContentType,
      method: configJSON.apiGetMethod,
      endPoint: `account_block/accounts/${this.state.qrUserId}/get_user_profile`,
    });
  };

  //================ Customizable Area End ========================//



  //================ Customizable Area Start ========================//

  getUserDetailSuccessCallBack = (res: any) => {
    this.setState({
      getUserDetail: res.data.attributes
    }, () => {  })
  }

  getUserDetailFailureCallBack = (res: any) => {
  }
}

//================ Customizable Area End ========================//
