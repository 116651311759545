import { Platform } from 'react-native';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { ToastContainer, toast } from "react-toastify";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import StorageProvider from "../../../framework/src/StorageProvider";
import { LoginManager, AccessToken } from "react-native-fbsdk";
import {
  // GoogleSignin,
  GoogleSigninButton,
  statusCodes,
} from '@react-native-community/google-signin';


export const configJSON = require("./config");

export interface Props {
  history: any;
  id: string;
  location: any;
}

export interface S {
  //========== Customizable Area Start =============//
  fullName: string;
  otp: string;
  otpToken: any

  //============ Customizable Area End ===============//
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class ForgotPasswordOtpController extends BlockComponent<
  Props,
  S,
  SS
> {
  //========== Customizable Area Start ============//
  signUpApiCallID: any;
  postOTPApiCallId: string;
  _unsubscribe: any;
  //============ Customizable Area End ============//

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      // Customizable Area Start
      // Customizable Area Start 
    ];

    this.state = {
      //======== Customizable Area Start ===========//
      fullName: '',
      otp: '',
      otpToken: this.props.location.state ? this.props.location.state.token : ""

      //========= Customizable Area End ============//
    };

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    let getotp = localStorage.getItem("token");
    console.log("@@@ componentDidMount =================================", getotp)
  }

  async componentWillUnmount() {

    // Customizable Area Start
    // Customizable Area Start 
  }


  //================== Customizable Area Start ==================//

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson.status === 500) {
        alert(responseJson.error);
        return;
      }
      if (responseJson && responseJson.messages) {
        console.log("@@@ messages ===========")
        if (apiRequestCallId === this.postOTPApiCallId) {
          this.onOTPSuccessCallBack(responseJson);
        }
      } else if (responseJson && responseJson.errors) {
        console.log("@@@ errors ===========", responseJson)
        if (apiRequestCallId === this.postOTPApiCallId) {
          this.userOPTFailureCallBack(responseJson);
        }
      }
    } else {
      this.parseApiCatchErrorResponse(errorReponse);
    }
  };

  //================== Customizable Area End ==================//



  //================== Customizable Area Start ==================//

  onOTPSuccessCallBack = (res: any) => {
    toast.success("OTP validation success")
    this.props.history.push('/bx_block_forgot_password/resetPassword', { token: this.state.otpToken })
  }

  userOPTFailureCallBack = (error: any) => {
    toast.error("Please enter your valid OTP")

  }

  //================== Customizable Area End ==================//


  //================== Customizable Area Start ==================//

  apiCall = async (data: any) => {
    const { contentType, method, endPoint, body } = data;

    const header = {
      "Content-Type": contentType,
      // token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  //================== Customizable Area End ==================//




  handleClose = () => {
    this.props.history.goBack()
  };

  //================== Customizable Area Start ==================//

  onSubmitOtp = async () => {
    console.log("token@@@@@@@", this.props.location.state.token)

    if (this.state.otp.length === 0) {
      alert("Please enter otp")
    } else if (this.state.otp.length < 4) {
      alert("please enter 4 digit otp")
    } else {

      // let getotp = localStorage.getItem("token");
      let getotp = this.props.location.state.token;


      // console.log("==getotp@@@@", getotp, localStorage.getItem("token"))
      let httpBody = {
        data: {
          token: getotp,
          otp_code: this.state.otp
        },
      };
      console.log("@@@ httpBody ==================", httpBody)
      this.postOTPApiCallId = await this.apiCall({
        contentType: configJSON.otpApiContentType,
        method: configJSON.otpMethod,
        endPoint: configJSON.otpApiEndPoint,
        body: httpBody
      });

    }
  }

  //================ Customizable Area End ======================//
}
