import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
export const configJSON = require("./config");
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import AppHeader from "../../../components/src/Header/index";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { withRouter } from "react-router-dom";
import withTheme from "../../../components/src/Theme/withTheme";
import moment from "moment";
import "../../../components/src/Styles/Styles.css";
import "../assets/css/common.css";
import Grid from "@material-ui/core/Grid";
import HomeController, { Props } from "./HomeController.web";
import SideBar from "./Sidebar.web";
import Loader from "../../../components/src/Loader.web";
import {
	createStyles,
} from "@material-ui/core";
import {
	mastercard,
} from "./assets";

// Customizable Area Start
class PaymentHistory extends HomeController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}

	// Customizable Area Start
	// Customizable Area End
	render() {
		const { classes } = this.props;
		const { monthly_payment_history, onetime_payment_history, monthlyError, subscription_monthly_payment_history, SubscriptionMonthlyError, subscription_onetime_payment_history, SubscriptionOnetimeError, monthlyOnetimeError } = this.state;
		//console.log(onetime_payment_history[0], "===@@@")
		return (
			<>
				<Loader loading={this.state.Loader} />
				<AppHeader />

				<div className={`catalog-book ${this.props.mode && "dark3"}`}>
					<SideBar
						selectedIndex={5}
						accountInfo={this.state.accountInfo && this.state.accountInfo} />
					<div className="content">
						<Breadcrumbs
							separator={<NavigateNextIcon fontSize="medium" />}
							aria-label="breadcrumb">
							<Link href="/Catalogue/Home" style={{ color: "#3b9dd4" }}>
								Home
							</Link>
							<Typography style={{ color: "#797b79 !important" }}>
								Payment History
							</Typography>
						</Breadcrumbs>
						<h3>Payment History</h3>

						<div className="history_sec">
							<Grid container spacing={3}>
								<Grid item xs={12} sm={12} md={6}>
									<div className="payment_history_wrapper">
										<div className={`purchase-history-card block-details ${this.props.mode && "dark1"}`}>
											<div className="top_heading">
												<span>Subscription Bought</span>
												<span className="tag-line">Recurring Per Month</span>
											</div>

											{subscription_monthly_payment_history && (
												<ul>
													{subscription_monthly_payment_history && subscription_monthly_payment_history.map((item: any, index: any) => {
														return (
															<li key={index}>
																<div className="fisrt-section">
																	<img
																		src={mastercard}
																		alt="master-card"
																		className="master-card"
																	/>
																	<div className="discription-part">
																		<p className={`${this.props.mode && "darkFo"}`}>
																			{item?.attributes?.order_transactions[0]?.order_id ? item?.attributes?.order_transactions[0]?.order_id : "-"}
																		</p>
																		<strong>
																			<span>••••</span> <span>••••</span>
																			<span className="color-gray">{item?.attributes?.order_transactions[0]?.last4 ? item?.attributes?.order_transactions[0]?.last4 : "-"}</span>
																		</strong>
																	</div>
																</div>

																<div className={`price-part ${this.props.mode && "darkFont"}`} >
																	<p>{item?.attributes?.order_transactions[0]?.created_at ? moment(item?.attributes?.order_transactions[0]?.created_at).format('DD MMM, YYYY') : "-"}</p>
																	<div className="price-row">
																		<span>{item?.attributes?.order_transactions[0]?.currency}</span>
																		<h5>{item?.attributes?.order_transactions[0]?.amount ? item?.attributes?.order_transactions[0]?.amount : "-"}</h5>
																	</div>
																</div>
															</li>
														)
													})}
												</ul>
											)}
											{SubscriptionMonthlyError && (
												<div className="text-center">
													<span className="errorcountry">{SubscriptionMonthlyError}</span>
												</div>
											)}

											<div className="divider"></div>

											<div className="top_heading">
												<span>Subscription Bought</span>
												<span className="tag-line">One Time Payment</span>
											</div>

											{subscription_onetime_payment_history && (
												<ul>
													{subscription_onetime_payment_history && subscription_onetime_payment_history.map((item: any, index: any) => {
														return (
															<li key={index}>
																<div className="fisrt-section">
																	<img
																		src={mastercard}
																		alt="master-card"
																		className="master-card"
																	/>
																	<div className="discription-part">
																		<p className={`${this.props.mode && "darkFo"}`}>
																			{item?.attributes?.order_transactions[0]?.order_id ? item?.attributes?.order_transactions[0]?.order_id : "-"}
																		</p>
																		<strong>
																			<span>••••</span> <span>••••</span>
																			<span className="color-gray">{item?.attributes?.order_transactions[0]?.last4 ? item?.attributes?.order_transactions[0]?.last4 : "-"}</span>
																		</strong>
																	</div>
																</div>

																<div className={`price-part ${this.props.mode && "darkFont"}`} >
																	<p>{item?.attributes?.order_transactions[0]?.created_at ? moment(item?.attributes?.order_transactions[0]?.created_at).format('DD MMM, YYYY') : "-"}</p>
																	<div className="price-row">
																		<span>{item?.attributes?.order_transactions[0]?.currency}</span>
																		<h5>{item?.attributes?.order_transactions[0]?.amount ? item?.attributes?.order_transactions[0]?.amount : "-"}</h5>
																	</div>
																</div>
															</li>
														)
													})}
												</ul>
											)}
											{SubscriptionOnetimeError && (
												<div className="text-center">
													<span className="errorcountry">{SubscriptionOnetimeError}</span>
												</div>
											)}
										</div>
									</div>
								</Grid>


								<Grid item xs={12} sm={12} md={6}>
									<div className="payment_history_wrapper">
										<div className={`purchase-history-card block-details ${this.props.mode && "dark1"}`}>
											<div className="top_heading">
												<span>Books Bought</span>
												<span className="tag-line">Recurring Per Month</span>
											</div>

											{monthly_payment_history && (
												<ul>
													{monthly_payment_history && monthly_payment_history.map((item: any, index: any) => {
														let x = item?.attributes?.currency;
														return (
															<li key={index}>
																<div className="fisrt-section">
																	<img
																		src={mastercard}
																		alt="master-card"
																		className="master-card"
																	/>
																	<div className="discription-part">
																		<p className={`${this.props.mode && "darkFo"}`}>
																			{item?.attributes?.order_id ? item?.attributes?.order_id : "-"}
																		</p>
																		<strong>
																			<span>••••</span> <span>••••</span>
																			<span className="color-gray">{item?.attributes?.last4 ? item?.attributes?.last4 : "-"}</span>
																		</strong>
																	</div>
																</div>
																<div className={`price-part ${this.props.mode && "darkFont"}`} >
																	<p>{item?.attributes?.created_at ? moment(item?.attributes?.created_at).format('DD MMM, YYYY') : "-"}</p>
																	<div className="price-row">
																		<span>
																			{x === "INR" ? "₹" : x === "USD" ? "$" : x === "CAD" ? "CA$" : x === "GBP" ? "£" : ""}
																		</span>
																		<h5>{item?.attributes?.amount ? item?.attributes?.amount : "-"}</h5>
																	</div>
																</div>
															</li>
														)
													})}
												</ul>
											)}
											{monthlyError && (
												<div className="text-center">
													<span className="errorcountry">{monthlyError}</span>
												</div>
											)}

											<div className="divider"></div>

											<div className="top_heading">
												<span>Books Bought</span>
												<span className="tag-line">One Time Payment</span>
											</div>

											{onetime_payment_history && (
												<ul>
													{onetime_payment_history && onetime_payment_history.map((item: any, index: any) => {
														var x = item?.attributes?.currency;

														return (
															<li key={index}>
																<div className="fisrt-section">
																	<img
																		src={mastercard}
																		alt="master-card"
																		className="master-card"
																	/>
																	<div className="discription-part">
																		<p className={`${this.props.mode && "darkFo"}`}>
																			{item?.attributes?.order_id ? item?.attributes?.order_id : "-"}
																		</p>
																		<strong>
																			<span>••••</span> <span>••••</span>
																			<span className="color-gray">{item?.attributes?.last4 ? item?.attributes?.last4 : "-"}</span>
																		</strong>
																	</div>
																</div>

																<div className={`price-part ${this.props.mode && "darkFont"}`} >
																	<p>{item?.attributes?.created_at ? moment(item?.attributes?.created_at).format('DD MMM, YYYY') : "-"}</p>
																	<div className="price-row">
																		<span>{x === "INR" ? "₹" : x === "USD" ? "$" : x === "CAD" ? "CA$" : x === "GBP" ? "£" : ""}</span>
																		<h5>{item?.attributes?.amount ? item?.attributes?.amount : "-"}</h5>
																	</div>
																</div>
															</li>
														)
													})}
												</ul>
											)}
											{monthlyOnetimeError && (
												<div className="text-center">
													<span className="errorcountry">{monthlyOnetimeError}</span>
												</div>
											)}
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				</div>

			</>
		);
	}
}
// Customizable Area End

//@ts-ignore
const PaymentHistoryRouter = withRouter(PaymentHistory);
const PaymentHistoryTheme = withTheme(PaymentHistoryRouter);

// Customizable Area Start
export default withStyles((theme) =>
	createStyles({
		cardRoot: {
			maxWidth: "400px",
			margin: "20px",
		},
		dark3: {
			backgroundColor: "#2f3134 !important",
			color: "#fff !important",
			height: "100vh",
		},
		dark2: {
			backgroundColor: "#424242 !important",
			color: "#fff !important",
		},
	})
)(PaymentHistoryTheme);
// Customizable Area End

	// Customizable Area Start
	// Customizable Area End