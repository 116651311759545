// Customizable Area Start
import React, { useEffect, useState } from "react";
//Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
//Customizable Area End
import { withRouter, Link } from "react-router-dom";
import "../../../blocks/catalogue/assets/css/home.css"
import "../../../blocks/catalogue/assets/css/common.css"
import withTheme from "../Theme/withTheme";
import { Grid } from "@material-ui/core";
// import { withLoader } from "../withLoader.Web";
// import { withAlertBox } from "../withAlertBox.Web";

const styles = {
  searchIcon: {
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    left: "3%",
    alignItems: "center",
    justifyContent: "center",
  },
  search: {
    position: "relative",
    background: "#5196DD",
    color: "white",
    width: "100%",
    borderRadius: "8px",
    marginTop: "2%",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    width: "100%",
    padding: "13px 50px",
  },
  cardContainer: {
    height: "auto",
    backgroundColor: "white",
    width: "auto",
    margin: "10px",
    borderRadius: "8px",
  },
  formControl: {
    minWidth: 120,
  },
} as any;

  // Customizable Area Start    

const SimilarBook = (props: any) => {
  const { classes } = props;
  const location = window.location.pathname.split('/')[2];
  const [symbol, setSymbol] = useState("");

  const handleBookfilter = props?.allBooks && props?.allBooks.filter((item: any) => {
    return item.attributes.is_purchased === false
  })

  useEffect(() => {
    let localData = localStorage.getItem("symbol");
    //@ts-ignore
    setSymbol(localData);
  }, [])

  return (
    <>
      <div className={`book-items-main ${location == "BookDetails" && "similarbook-page"} ${props.mode && "dark1"}`}>
        <div className='similiarbooktext'> {location == "BookDetails" && <>Similar Books</>} </div>
        <Grid className='itemcontainer' container spacing={1}>
          <div className='itemcontainerleft' >
            <Grid container spacing={1}>
              {props?.allBooks && handleBookfilter.slice(0, 6).map((x: any, index: any) => {
                return (
                  <Grid item xs={6} md={3} sm={3} lg={2}>
                    <div className='similarimageDiv' key={index}>
                      <img className='similarimage' src={x.attributes.cover_image_url} onClick={() => {
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                        props.history.push(`/Catalogue/BookDetails/${x.id}`);
                      }} />


                      <img
                        className="play_btn"
                        src={require("./images/Play Button.png")}
                        onClick={() => {
                          //@ts-ignore
                          props.history.replace(`/book-read/${x.attributes.id}`);
                        }} />


                      <div className='similarbookName'>
                        <p className={`image-name ${props.mode && "darkFont"}`}>
                          {x.attributes.name}
                        </p>
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>

          {window.location.pathname != (`/Catalogue/Home`) && window.location.pathname.split("/")[1] != (`package-books`) && props.allBooks && (
            <Grid className='similarBookViewall' item xs={12} md={2}
              onClick={() => { props.history.push('/view-allBooks') }}>

              <div className="similarBookViewalltext"> View All Book
              </div>
              <div>
                <button className="View-btn-next" />
              </div>
            </Grid>
          )}

        </Grid>
      </div>
    </>
  )
}

  // Customizable Area Start    
const BookListingRouter = withRouter(SimilarBook);
const BookListingTheme = withTheme(BookListingRouter);
export default withStyles(styles)(BookListingTheme);