Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.CatelogueLabel = "Book Details";

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePost = "POST";
exports.apiMethodTypePut = "PUT";
exports.apiMethodTypeDelete = "DELETE";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.GetAllBooksAPiEndPoint = "bx_block_book/books";
exports.GetMyBooksAPiEndPoint = "bx_block_book/my_books";
exports.getAllNotesAPiEndPoint = "bx_block_notes/my_notes";
exports.postSearchBookAPiEndPoint = "bx_block_book/search_book";
exports.putUpdateProfileAPiEndPoint = "account_block/accounts/";
exports.postCreateCartAPiEndPoint = "bx_block_order_management/orders";
exports.errorNameEmpty = "Field is required";
exports.getActiveCartAPiEndPoint = "bx_block_order_management/get_active_cart";
exports.getBuyPackagesAPiEndPoint = "bx_block_customisableusersubscriptions/subscription_packages";
exports.getBestSellerBooksAPiEndPoint = "bx_block_book/best_seller";
exports.getAnalyticsAPiEndPoint = "bx_block_analytics/student_dashboard";
exports.getEarningsAPiEndPoint = "bx_block_analytics/student_dashboard/monthly_earning_week_wise";
exports.postCreateNotesAPiEndPoint = "bx_block_notes/notes";
exports.getNoteTextAPiEndPoint = "bx_block_notes/show_note_text";
exports.deleteNoteTextAPiEndPoint = "bx_block_notes/note_text";
exports.putEditNoteTextAPiEndPoint = "bx_block_notes/update_note_text";
exports.putEditBookNoteAPiEndPoint = "bx_block_notes/set_for_sale";
exports.getAllNotesForPurchasedAPiEndPoint = "bx_block_notes/show_buy_notes";
exports.getBookNotesForPurchasedAPiEndPoint = "bx_block_notes/show_note_sale";
exports.buynotesUserAPiEndPoint = "bx_block_notes/note";
exports.getpurchasedNotesAPiEndPoint = "bx_block_notes/purchased_notes";
exports.postProductReviewAPiEndPoint = "bx_block_reviewprompt/note_reviews";
exports.postRefundBookAPiEndPoint = "bx_block_payments/refund";
exports.getMyPackagesAPiEndPoint = "bx_block_customisableusersubscriptions/user_subscriptions";
exports.postaddPackageBooksAPiEndPoint = "bx_block_order_management/orders/add_books_to_subscription";
exports.postCancelPassAPiEndPoint = "bx_block_payments/refund_pass";

exports.bookPaymentHistoryPerMonthAPiEndPoint = "bx_block_payments/book_payment_history_per_month";
exports.bookPaymentHistoryOneTimeAPiEndPoint = "bx_block_payments/book_payment_history_one_time";
exports.subscriptionPaymentHistoryMonthAPiEndPoint = "bx_block_payments/subscription_payment_history";
exports.subscriptionOnetimePaymentHistoryAPiEndPoint = "bx_block_payments/subscription_payment_history_one_time";

exports.getCountryCurrencyEndPoint = "account_block/all_country";
exports.postCountryCurrencyEndPoint = "account_block/set_currency";
exports.getBooksAvailableSlotApiEndPoint = "bx_block_book/get_available_slots";
exports.postChapterWiseAudioApiEndPoint = "bx_block_book/get_chapter_wise_audio";
// exports.GetBookSlotApiEndPoint = "bx_block_book/get_available_slots"

// Customizable Area End
