import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import HomeController, { Props, S } from "./HomeController.web";
export const configJSON = require("./config");
import SideBar from "./Sidebar.web";
import AppHeader from "../../../components/src/Header/index";
import BookListing from "../../../components/src/BookListing/index";
import { withRouter, Link } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import "../assets/css/home.css";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
import withDialog from "../../../components/src/withDialog.web";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withTheme from "../../../components/src/Theme/withTheme"
import {
  bookLogo,
  playButton,
  Published,
  selectArrow,
  downloads,
  Notes,
  earnings,
  subscription,
  paymentHistory,
  purchasedNotes,
} from "./assets";
//import styles from '../assets/css/index'

const styles = {
  searchIcon: {
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    left: "3%",
    alignItems: "center",
    justifyContent: "center",
  },
  search: {
    position: "relative",
    background: "#5196DD",
    color: "white",
    width: "100%",
    borderRadius: "8px",
    marginTop: "2%",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    width: "100%",
    padding: "13px 50px",
  },
  cardContainer: {
    height: "auto",
    backgroundColor: "white",
    width: "auto",
    margin: "10px",
    borderRadius: "8px",
  },
  formControl: {
    minWidth: 120,
  },
} as any;

// Customizable Area Start    

class Home extends HomeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <Loader loading={this.state.Loader} />
        <AppHeader />
        <div className={`catalog-home ${this.props.mode && "dark3"}`} >
          <SideBar
            selectedIndex={0}
            accountInfo={this.state.accountInfo && this.state.accountInfo}
            className="col-2"
          />
          <div className="content">
            <p className="color-gray">Home</p>
            <p className="color-gray mb-0" style={{ textTransform: "capitalize" }}>Welcome {this.state.accountInfo && this.state.accountInfo?.attributes?.full_name},</p>
            <h3 className={`color-gray ${this.props.mode && "dark2"}`} style={{ fontSize: "25px", fontWeight: 'bold', color: 'black' }}>Choose Your Book</h3>

            <BookListing allBooks={this.state.allBooks && this.state.allBooks} />

          </div>
        </div>
      </>
    );
  }
}

// Customizable Area End

const HomeAlertBox = withAlertBox(Home);
const HomeLoader = withLoader(HomeAlertBox);
const HomeToast = withToast(HomeLoader);
const HomeWithDialog = withDialog(HomeToast);
const HomeWithRouter = withRouter(HomeWithDialog);
const HomeWithRoutertheme = withTheme(HomeWithRouter);
export default withStyles(styles)(HomeWithRoutertheme);

// Customizable Area Start
// Customizable Area End