import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import StorageProvider from "../../../framework/src/StorageProvider.web";
import { RouteProps, RouteComponentProps } from "react-router-dom";
import { withAlertBoxProps } from "../../../components/src/withAlertBox.Web";
import { withToastProps } from "../../../components/src/withSnackBar.Web";
import { withLoaderProps } from "../../../components/src/withLoader.Web";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Customizable Area End

export const configJSON = require("./config");

export type Props = {
    authToken: any;
    classes: any;
    mode: any;
    setTheme: any;
    cartDetails: any;
    stripe: any;
    elements: any;
    navigation: any;
    id: string;
} & RouteProps &
    RouteComponentProps &
    withAlertBoxProps &
    withToastProps &
    withLoaderProps & {
        id: string;
    };

export interface S {
    authToken: any;
    Loader: any;
    searchInput: any;
    accountInfo: any;
    reviewOrder: any;
    cardDetailsSubmitted: any;
    orderId: any;
    cartInfo: any;
    bookCart: any;
    notesCart: any;
    symbol: any;
    toggleMonth: boolean;
    paymentToken: any;
    singlePaymentTokenID: any;
    stripe: any;
    stripeElement: any;
    currencyCode: any;
    orderdata: any;
    orderBookCover: any;
    no_of_split: any;
    shared_price: any;
    cartItem: any;
    new_currency_code: any;
    get_number_book: any;

    abc: any
    // elements: any;
}

interface SS {
    id: any;
}



export default class BuyBookController extends BlockComponent<Props, S, SS> {
    getStudentsListCallId: any;
    postSearchBookApiCallId: string = "";
    postcreateorderApiCallId: string = "";
    getCartApiCallId: string = "";
    delCartItemApiCallId: string = "";
    postPaymentIntentApiCallId: string = "";
    postBuyPackageApiCallId: string = "";
    confirmPackageApiCallId: string = "";
    singleBookPaymentConfirmApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

        this.state = {
            authToken: "",
            Loader: false,
            searchInput: "",
            accountInfo: "",
            reviewOrder: false,
            cardDetailsSubmitted: false,

            abc: "vvvvvv",

            orderId: "",
            cartInfo: "",
            bookCart: "",
            notesCart: "",
            symbol: "",
            toggleMonth: false,
            paymentToken: "",
            singlePaymentTokenID: "",
            stripe: {},
            stripeElement: {},
            currencyCode: "",
            orderdata: "",
            orderBookCover: "",
            no_of_split: "",
            shared_price: "",
            cartItem: "",
            new_currency_code: "",
            get_number_book: "",
            // elements: "",
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    componentDidMount = async () => {
        const accountInfo = await StorageProvider.get("accountInfo");
        const authToken = await StorageProvider.get("authToken");
        const bookId = await StorageProvider.get("bookId");
        const cartId = await StorageProvider.get("orderId");
        const curruncy = await StorageProvider.get("currencyCode")
        const localData = await localStorage.getItem("symbol");


        this.setState({
            no_of_split: await StorageProvider.get("no_of_split"),
            shared_price: await StorageProvider.get("shared_price"),
            cartItem: await StorageProvider.get("cartItem"),
            new_currency_code: localStorage.getItem("currency_code"),
            get_number_book: localStorage.getItem("number_book"),
        })

        console.log(this.state.new_currency_code, "===@@@new_currency_code")

        if (authToken) {
            this.setState({
                accountInfo: JSON.parse(accountInfo),
                authToken: authToken,
                orderId: cartId,
                currencyCode: curruncy
            });
            this.setState({
                symbol: localData
            });

            // this.postcreateorder(bookId);
            cartId && this.getCart(cartId);
        } else {
            const { history }: any = this.props;
            history.push("/");
        }
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (responseJson && !responseJson.errors) {
                const apiRequestCallId = message.getData(
                    getName(MessageEnum.RestAPIResponceDataMessage)
                );
                if (apiRequestCallId != null) {
                    //create order
                    if (apiRequestCallId === this.postcreateorderApiCallId) {
                        this.setState({
                            Loader: false,
                        });
                    }

                    //get cart
                    if (apiRequestCallId === this.getCartApiCallId) {
                        console.log(responseJson, "===@@@")
                        const bookCart = responseJson?.data?.order?.data?.attributes?.order_items.filter((item: any) => {
                            return item.attributes.book != null
                        })
                        const notesCart = responseJson?.data?.order?.data?.attributes?.order_items.filter((item: any) => {
                            return item.attributes.note != null
                        })

                        this.setState({
                            cartInfo: responseJson?.data?.order?.data,
                            bookCart: bookCart,
                            notesCart: notesCart,
                            Loader: false,
                        })
                    }

                    //del cart item
                    if (apiRequestCallId === this.delCartItemApiCallId) {
                        this.gettingOrderID();
                        toast.success(responseJson.message);
                        this.state.cartInfo.attributes.order_items.length == 1 &&
                            //@ts-ignore
                            this.props.history.push('/cart');
                        this.setState({
                            Loader: false,
                        });
                    }

                    //post payment Create
                    if (apiRequestCallId === this.postPaymentIntentApiCallId) {
                        if (responseJson && responseJson?.data) {
                            console.log("jhrfkhkfhfghdjkghfjgdfjkgfjkgh=========", responseJson?.data?.order?.data)
                            this.singleBookPaymentConfirm(responseJson?.data?.order?.data);
                        }
                    }

                    //post payment Intent
                    if (apiRequestCallId === this.singleBookPaymentConfirmApiCallId) {
                        if (responseJson?.data) {
                            this.setState({
                                orderdata: responseJson?.data
                            })
                            toast.success(responseJson?.data?.message);
                            this.removingOrderId();
                            //@ts-ignore
                            this.props.history.push("/order-placed/book", this.state.cartInfo.attributes.order_items[0].attributes.book.attributes.cover_image_url);
                            // this.props.history.push("/order-placed/book", this.state.abc);
                        }
                        this.setState({
                            Loader: false,
                        });
                    }

                    //post buy packages 
                    if (apiRequestCallId === this.postBuyPackageApiCallId) {
                        console.log(responseJson, "===@@@")
                        if (responseJson?.data?.client_secret) {
                            this.confirmBuyPackage(responseJson?.data?.client_secret);
                        }
                    }

                    if (apiRequestCallId === this.confirmPackageApiCallId) {
                        if (responseJson?.data) {
                            this.setState({
                                orderdata: responseJson?.data
                            })
                            console.log(responseJson?.data, "===@@@")
                            console.log(this.state.get_number_book, "===@@@")
                            toast.success(responseJson?.data?.message);
                            this.removingOrderId();
                            const updatePackage = this.state.accountInfo;
                            updatePackage.attributes.have_package = true;
                            this.setState({
                                accountInfo: updatePackage,
                            });
                            StorageProvider.set("accountInfo", JSON.stringify(updatePackage));
                            //@ts-ignore
                            // this.props.history.push("/order-placed/package");
                            this.props.history.push(`/package-books/${localStorage.getItem("number_book")}?package_id=${responseJson?.data.id}&order_id=${responseJson?.data.order_id}`);
                        }
                        this.setState({
                            Loader: false,
                        });
                    }
                }
            }

            if (responseJson?.errors) {
                console.log("responseJson==abc", responseJson)
                responseJson?.errors[0].token == "Token has Expired" && StorageProvider.remove("authToken");
                this.sessionLogout();
            }

        }
        // Customizable Area End
    }

    // for session expire
    sessionLogout = async () => {
        const accountInfo = await StorageProvider.get("accountInfo");

        this.props
            .showAlert({
                title: "Session Expired",
                message: "Please Login again!",
            })

            .then((data: any) => {
                StorageProvider.remove("authToken");
                StorageProvider.remove("orderId");
                StorageProvider.remove("accountInfo");
            })

            .then(() => {
                StorageProvider.remove("orderId");
            })

            .then(() => {
                const { history } = this.props;
                history.push("/");
            });
    };

    postcreateorder = (id: any): boolean => {
        this.setState({
            Loader: true,
        });
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };

        const httpBody = {
            book_id: id,
            quantity: "1",
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.postcreateorderApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postCreateOrderAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };

    // get all books
    getCart = (id: any): boolean => {
        this.setState({
            Loader: true,
            orderId: id
        });
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCartApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postCreateOrderAPiEndPoint + `/${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    // delCartItem
    delCartItem = (id: any): boolean => {
        this.setState({
            Loader: true,
        });
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: this.state.authToken,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.delCartItemApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.delCartItemAPiEndPoint + `/${id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    // post payment intent for Purchased single book
    postPaymentIntent = (id: any, { ...paylod }) => {
        this.setState({
            Loader: true,
            singlePaymentTokenID: id,
        });

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.authToken,
        };
        const priceType = localStorage.getItem("price_type");
        console.log("@@@===@", priceType)
        const httpBody = {
            data: {
                attributes: {
                    order_id: this.state.orderId,
                    currency: this.state.new_currency_code,
                    // price_type: paylod.TYPE
                    price_type: priceType,
                },
                payment_token: this.state.singlePaymentTokenID,
            },
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postPaymentIntentApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.postPaymentIntentAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        console.log("@==@", requestMessage)
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    singleBookPaymentConfirm = async (data: any) => {
        this.setState({
            Loader: true,
        });

        // console.log(item.attributes.purchase_type, "===098")

        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.authToken,
        };

        const priceType = localStorage.getItem("price_type");
        const httpBody = {
            data: {
                attributes: {
                    order_id: this.state.orderId,
                    currency: this.state.new_currency_code,
                    pay_per_month: this.state.toggleMonth,
                    // price_type: item.attributes.purchase_type
                    price_type: priceType
                },
                payment_token: this.state.singlePaymentTokenID,
            },
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.singleBookPaymentConfirmApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.confirmSingleBookPaymentAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        // const result = await this.state.stripe.confirmPaymentIntent(id, {
        //     payment_method: this.state.singlePaymentTokenID,
        // });
        // if (result.paymentIntent.status === "succeeded") {
        //     const httpBody = {
        //         data: {
        //             attributes: {
        //                 order_id: this.state.orderId,
        //                 currency: this.state.symbol,
        //                 pay_per_month: this.state.toggleMonth
        //             },
        //             // status: "succeeded",
        //             payment_token: result.paymentIntent.id,
        //         },
        //     };
        //     const requestMessage = new Message(
        //         getName(MessageEnum.RestAPIRequestMessage)
        //     );
        //     this.singleBookPaymentConfirmApiCallId = requestMessage.messageId;
        //     requestMessage.addData(
        //         getName(MessageEnum.RestAPIResponceEndPointMessage),
        //         configJSON.confirmSingleBookPaymentAPiEndPoint
        //     );
        //     requestMessage.addData(
        //         getName(MessageEnum.RestAPIRequestHeaderMessage),
        //         JSON.stringify(headers)
        //     );
        //     requestMessage.addData(
        //         getName(MessageEnum.RestAPIRequestBodyMessage),
        //         JSON.stringify(httpBody)
        //     );
        //     requestMessage.addData(
        //         getName(MessageEnum.RestAPIRequestMethodMessage),
        //         configJSON.exampleAPiMethod
        //     );
        //     runEngine.sendMessage(requestMessage.id, requestMessage);
        // }
        return true;
    };


    //Buy Packages API
    postBuyPackage = (id: any, data: any) => {


        this.setState({
            Loader: true,
            stripe: data,
            paymentToken: id
        });
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: this.state.authToken,
        };
        const isMonthly = window.location.pathname.split("/");
        const httpBody = {
            data: {
                attributes: {
                    package_id: isMonthly[2],
                    currency: localStorage.getItem("currency_code"),
                    is_monthly: isMonthly[3] === "monthly" ? true : false
                },
                payment_token: this.state.paymentToken
            }
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postBuyPackageApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createPackagesApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    };

    confirmBuyPackage = async (id: any) => {
        this.setState({
            Loader: true,
        });
        const headers = {
            "Content-Type": configJSON.validationApiContentType, token: this.state.authToken,
        };
        const result = await this.state.stripe.confirmPaymentIntent(id, {
            payment_method: this.state.paymentToken,
        });

        if (result.paymentIntent.status === "succeeded") {
            const isMonthly = window.location.pathname.split("/")
            const httpBody = {
                data: {
                    attributes: {
                        package_id: isMonthly[2],
                        currency: this.state.symbol,
                        is_monthly: isMonthly[3] == "monthly" ? true : false
                    },
                    status: "succeeded",
                    payment_token: result.paymentIntent.id
                }
            };
            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.confirmPackageApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.confirmPackagesApiEndPoint
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(httpBody)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }

        return true;
    };

    gettingOrderID = async () => {
        const order_id = await StorageProvider.get("orderId");
        this.getCart(order_id);
    };

    removingOrderId = async () => {
        await StorageProvider.remove("orderId");
    }
}
