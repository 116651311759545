import React from "react";
// Customizable Area Start
import Typography from "@material-ui/core/Typography";
import { withStyles, createStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { shadows } from "@material-ui/system";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
//import { bookCover, viewIcon } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// Customizable Area End
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
export const configJSON = require("./config");
import "../../../components/src/Styles/Styles.css";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import AppHeader from "../../../components/src/Header/index";
import HomeController, { Props } from "./HomeController.web";
import { withRouter } from "react-router-dom";
import "../assets/css/home.css";
import "../assets/css/notes.css";
import "../assets/css/mybook.css";
import "../assets/css/common.css";
import withTheme from "../../../components/src/Theme/withTheme";

import {
    bookLogo,
    playButton,
    Published,
    selectArrow,
    downloads,
    Notes,
    earnings,
    subscription,
    paymentHistory,
    purchasedNotes,
    bookCover,
    cartIcon,
} from "./assets";

import SideBar from "./Sidebar.web";
import Loader from "../../../components/src/Loader.web";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import BookListing from "../../../components/src/BookListing/index";
import ReactPlayer from "react-player";
import SimilarBook from "../../../components/src/BookListing/similarBook";

// Customizable Area Start
const styles = (theme: any) =>
    createStyles({
        cardRoot: {
            maxWidth: "400px",
            margin: "20px",
        },
        aboutBookTitle: {
            width: "80px",
            [theme.breakpoints.down("sm")]: {
                width: "30%",
            },
        },
        aboutBookValue: {
            wordBreak: "break-all",
            [theme.breakpoints.down("sm")]: {
                width: "70%",
            },
        },
    } as any);
// Customizable Area End

// Customizable Area Start
class BookDetails extends HomeController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
    render() {
        const { classes } = this.props;
        const bookslot = this.state.availableslots && this.state.availableslots.available_slots;
        const book = this.state.bookDetail && this.state.bookDetail.attributes;


        console.log("@@===availableslots======",this.state.availableslots)
        console.log("@@=========",this.state.bookDetail)
        return (
            <>
                <Loader loading={this.state.Loader} />
                <AppHeader />

                <div className={`catalog-book book-details ${this.props.mode && "dark3"}`}>
                    <SideBar
                        selectedIndex={8}
                        accountInfo={this.state.accountInfo && this.state.accountInfo} />
                    <div className="content">
                        <Breadcrumbs
                            separator={<NavigateNextIcon fontSize="small" />}
                            aria-label="breadcrumb"
                        >
                            <Link href="/Catalogue/Home" style={{ color: "#3b9dd4" }}>
                                Home
                            </Link>
                            <Link href="/view-allBooks" style={{ color: "#3b9dd4" }}>
                                In Stores
                            </Link>
                            <Typography style={{ color: "#797b79 !important" }}>
                                Book Details
                            </Typography>
                        </Breadcrumbs>

                        <h3>Book Details</h3>
                        {book && (
                            <>
                                <div className="bookdetails-wrapper" id="BookDt">
                                    <div className="book-intro-wrap">
                                        <div className={`book-intro ${this.props.mode && "dark1"}`}>
                                            <div className="image-name">
                                                <div className="book-image">
                                                    <img src={book.cover_image_url} alt="bookcoverpage" />
                                                </div>
                                                <div className="book-name">
                                                    <strong>
                                                        {book.name}: {book.author}
                                                    </strong>
                                                    <small>{book.author}</small>
                                                </div>
                                            </div>
                                            <span
                                                className="count"
                                                style={{ color: `${this.props.mode && "black"}` }}>
                                                {book.view_count}
                                            </span>
                                        </div>

                                        <div className="read-box">
                                            <button
                                                className={`btn click-pointer ${this.props.mode && "dark1"}`}
                                                onClick={() => {
                                                    const { history }: any = this.props;
                                                    history.push(`/book-read/${book.id}`);
                                                    // this.getBooksAvailableSlot()
                                                }}>
                                                Read or Listen to a sample
                                            </button>
                                        </div>

                                        <div className="buynow-block">
                                            {book.is_in_cart ? (
                                                <button
                                                    className="buy-now"
                                                    onClick={() => {
                                                        this.props.history.push("/cart");
                                                    }}>
                                                    GO TO CART
                                                </button>
                                            ) : (
                                                <button
                                                    className="buy-now"
                                                    onClick={async () => {
                                                        localStorage.setItem("price_type", "full_price");
                                                        await this.settingBookId(book.id);
                                                        await this.postCreateCart({ ID: book.id, TYPE: "full_price" });
                                                    }}>
                                                    BUY NOW FOR {this.state.symbol}{book.full_price}
                                                </button>
                                            )}

                                            {/* <Button
                                                color="default"
                                                className="cart_icon_btn"
                                                onClick={async () => {
                                                    await this.settingBookId(book.id);
                                                    await this.postCreateCart({ID:book.id,TYPE:"full_price"});
                                                    await this.props.history.push("/cart");
                                                }}>
                                                <img src={cartIcon} alt="icon" className="img-fluid" />
                                            </Button> */}

                                        </div>
                                    </div>

                                    <div className={`book-description ${this.props.mode && "dark1"}`}>
                                        <h4>About Book:</h4>
                                        <div>
                                            <ul className="aboutBookUL">
                                                <li className="li-class">
                                                    <span className={classes.aboutBookTitle}>Written By: </span>
                                                    <strong className={classes.aboutBookValue}>{book.author}</strong>
                                                </li>
                                                <li className="li-class">
                                                    <span className={classes.aboutBookTitle}>Language: </span>
                                                    <strong className={classes.aboutBookValue}>{book.language}</strong>
                                                </li>
                                                <li className="li-class">
                                                    <span className={classes.aboutBookTitle}>ISBN: </span>
                                                    <strong className={classes.aboutBookValue}>{book.isbn}</strong>
                                                </li>
                                                <li className="li-class">
                                                    <span className={classes.aboutBookTitle}>Length: </span>
                                                    <strong className={classes.aboutBookValue}>{book.length_in_string}</strong>
                                                </li>
                                                <li className="li-class">
                                                    <span className={classes.aboutBookTitle}>Publisher: </span>
                                                    <strong className={classes.aboutBookValue}>{book.publisher_name}</strong>
                                                </li>
                                            </ul>
                                        </div>
                                        <div
                                            className={`summary-block ${this.props.mode && "dark2"}`}>
                                            <h4>Summary:</h4>
                                            <div className="custom-scroll">
                                                <p>{book.summary}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="share_pricing">
                                    <Grid container spacing={4}>
                                        <>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <div className="pricing_box">
                                                    <button className="pricing_btn"
                                                        onClick={async () => {
                                                            localStorage.setItem("price_type", "shared_price");
                                                            await this.settingBookId(book.id);
                                                            await this.postCreateCart({ ID: book.id, TYPE: "shared_price" });
                                                        }}>
                                                        Share and Buy for {this.state.symbol}{book.shared_price} only</button>
                                                    <p>Buy the book at half price for{" "}
                                                        {this.state.symbol}{book.shared_price}{" "}
                                                        only and share it with 4 other peers. The shared amount will be credited to your account.</p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                                <div className="pricing_box">
                                                    <button className="pricing_btn"
                                                        onClick={async () => {
                                                            localStorage.setItem("price_type", "reduced_price");
                                                            await this.settingBookId(book.id);
                                                            await this.postCreateCart({ ID: book.id, TYPE: "reduced_price" });
                                                        }}>
                                                        Rent at a Price of {this.state.symbol}{book.shared_price / book.no_of_split}</button>
                                                <p> {bookslot} slots are available at a shared Price. Grab your slot and buy the book for{" "}
                                                        {this.state.symbol}{book.shared_price / book.no_of_split} only.</p>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={4} md={4}>
                                  {/* {bookslot == 0 ? "zero" : bookslot== 1 ?"one" : bookslot== 2 ? "Two" : bookslot== 3 ?"Three" : bookslot== 4 ? "Four" : ""} */}
                                            </Grid>
                                        </>
                                    </Grid>
                                </div>

                                <SimilarBook 
                                 allBooks={this.state.allBooks && this.state.allBooks}/>

                                {/* <BookListing
                                    allBooks={this.state.allBooks && this.state.allBooks}
                                />  */}
                            </>
                        )}
                    </div>
                </div>
            </>
        );
    }
}
// Customizable Area End

const BookDetailsRouter = withRouter(BookDetails);
const BookDetailsTheme = withTheme(BookDetailsRouter);
export default withStyles(styles)(BookDetailsTheme);

// Customizable Area Start
// Customizable Area End
