// Customizable Area Start
import React from 'react';
import Button from "@material-ui/core/Button";
import "./currencypage.web.css"
// Customizable Area Start
import currencyController from "./currencyController.web"
// Customizable Area End
class Currencypage extends currencyController {
  render() {

    return (

      <div className="currencydiv">
        <div className="currencycontainer">
          <form>
            <h4 className="currencyselect">Select Country</h4>
            <ul>
              {this.state.countryName.map((item: any) => {
                return (
                  <li className='li_select_country' >
                    <input value={item.name} type="radio" id={item.id} name="age" onChange={() => this.handlechange(item.name)} className="currencyRadio" />
                    <label htmlFor={item.id}>{item.name}</label><br />
                  </li>
                )
              })}

              {/* <li className='li_select_country'>
                <input type="radio" id="age2" name="age" />
                <label htmlFor="age2" >India (INR)</label><br />
              </li>
              <li className='li_select_country' >
                <input type="radio" id="age3" name="age" />
                <label htmlFor="age3">United Kingdom (GBP)</label><br />
              </li>
              <li className='li_select_country' >
                <input type="radio" id="age4" name="age" />
                <label htmlFor="age4">United States (CAD)</label><br /><br />
              </li> */}

            </ul>
            <div className="currencybutton"><Button onClick={() => this.postCurrency()} variant="contained" style={{ backgroundColor: "#4caeef" }}  >
              Confirm Country
            </Button></div>
          </form>
        </div>
      </div>

    )
  }
}

export default Currencypage;
// Customizable Area End