import { Platform } from 'react-native';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from 'react-toastify';


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    classes: any;
    history: any;
    location: any;

}

export interface S {
    //================ Customizable Area Start ===============//
    fullName: string;
    otp: string;
    otpToken: any;
    password: any;
    confirmPassword: any

    //================== Customizable Area End ===================//
}

export interface SS {
    //======== Customizable Area Start ============//
    id: any;
    //======== Customizable Area End ===========//
}

export default class NewPasswordController extends BlockComponent<
    Props,
    S,
    SS
> {
    //========== Customizable Area Start ===========//
    postNewPasswordApiCallId: string;
    _unsubscribe: any;
    //========== Customizable Area End  =============//

    constructor(props: Props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),

        ];

        this.state = {
            //========== Customizable Area Start ============//
            fullName: '',
            otp: '',
            otpToken: this.props.location?.state?.token,
            password: '',
            confirmPassword: '',
            //=========== Customizable Area End =============//
        };

        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {

    }

    async componentWillUnmount() {

        // Customizable Area Start
        // Customizable Area Start 
    }

    //============= Customizable Area Start =============//

    async receive(from: string, message: Message) {
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            var errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if (responseJson.status === 500) {
                alert(responseJson.error);
                return;
            }
            if (responseJson) {
                if (apiRequestCallId === this.postNewPasswordApiCallId) {
                    this.onResetPasswordSuccessCallBack(responseJson);
                }
            } else if (responseJson && responseJson.errors) {
                if (apiRequestCallId === this.postNewPasswordApiCallId) {
                    this.onResetPasswordFailureCallBack(responseJson);
                }
            }
        } else {
            this.parseApiCatchErrorResponse(errorReponse);
        }
    };
    //============= Customizable Area End =============//


    //============= Customizable Area Start =============//

    onResetPasswordSuccessCallBack = (res: any) => {
        this.props.history.push('/EmailAccountLoginBlock')
        toast.success("Reset Password Successfully")
    }

    onResetPasswordFailureCallBack = (error: any) => {
    }

    //============= Customizable Area End =============//


    //============= Customizable Area Start =============//

    apiCall = async (data: any) => {
        const { contentType, method, endPoint, body } = data;
        const header = {
            "Content-Type": contentType,
            // token
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            method
        );
        body &&
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return requestMessage.messageId;
    };

    //============= Customizable Area End =============//


    handleClose = () => {
        this.props.history.goBack()
    };

    //============= Customizable Area Start =============//


    onSubmitNewPassword = async (value: any) => {

        if (value.password.length === 0) {
            alert("Please enter new password")
        } else if (value.confirmPassword.length === 0) {
            alert("please enter confirm password")
        } else {

            let httpBody = {
                data: {
                    token: this.state.otpToken,
                    new_password: value.confirmPassword
                },
            };
            this.postNewPasswordApiCallId = await this.apiCall({
                contentType: configJSON.resetPasswordApiContentType,
                method: configJSON.httpPostMethod,
                endPoint: configJSON.resetPasswordApiEndPoint,
                body: httpBody
            });

        }
    }

    //============ Customizable Area End ==============//
}
