import React from "react";
// Customizable Area Start
import {
    withStyles,
    createStyles,
    Grid,
    Box,
    Paper,
    Breadcrumbs,
    Typography,
    Link,
    Tooltip,
} from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import withAlertBox from "../../../components/src/withAlertBox.Web";
import withToast from "../../../components/src/withSnackBar.Web";
import withLoader from "../../../components/src/withLoader.Web";
// import withDialog from "../../../components/src/withDialog.web";
import withTheme from "../../../components/src/Theme/withTheme";
// Customizable Area End

import MyEarningsController, {
    Props,
    configJSON,
} from "./MyEarningsController";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { searchImage } from "./assets";

class MyEarnings extends MyEarningsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    componentDidMount = (): any => {
        if (this.props.authToken) {
            this.setState({ authToken: this.props.authToken }, () => {
                this.getMyEarnings();
            });
        }
    };
    componentDidUpdate = (prevProps: Props): any => {
        if (prevProps.authToken !== this.props.authToken) {
            this.setState({ authToken: this.props.authToken }, () => {
                this.getMyEarnings();
            });
        }
    };
    handleRoutes = (route: any) => {
        this.props.history.push(route);
    };
    // Customizable Area End

    render() {
        const { classes }: any = this.props;
        return (
            <Box className={classes.root}>
                <Grid item xs={12} sm={10} style={{marginLeft:"50px"}}>
                    <Breadcrumbs
                        separator={<NavigateNextIcon fontSize="small" />}
                        aria-label="breadcrumb"
                    >
                        <Link style={{ color: "#3b9dd4" }} href="/Publisher">
                            Home
                        </Link>
                        <Typography style={{ color: "#797b79 !important" }}>
                            My Earnings
                        </Typography>
                    </Breadcrumbs>
                    <Box mt={3}>
                        <Typography variant="h5">My Earnings</Typography>
                    </Box>
                    <Grid container component="div">
                        <Grid item xs={12} sm={8} md={5}>
                            <Paper elevation={3} className={`${classes.topViewEarning} ${this.props.mode && classes.darktopView}`}>
                                <Box
                                    display="flex"
                                    justifyContent="space-around"
                                >
                                    <Box>
                                        <ArrowBackIcon />
                                    </Box>
                                    <Box className={`${classes.month} ${this.props.mode && classes.darkMonth}`}>
                                        <Typography variant="subtitle1">
                                            This Month <ExpandMore />
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary">
                                            {/* ${this.state.prevDate.toLocaleString("default", { month: "short" })}  */}
                                            {`${this.state.prevDate.getDate()} - 
                                            ${this.state.todayDate.getDate()} ${this.state.todayDate.toLocaleString("default", { month: "long" })} `}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <ArrowForwardIcon />
                                    </Box>
                                </Box>
                            </Paper>
                            {this.state.myEarnings && (
                                <Paper
                                    elevation={2}
                                    className={`${classes.myEarningsView} ${this.props.mode && classes.darktopView}`}
                                >
                                    <Box p={3} textAlign="center">
                                        <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="baseline"
                                        >
                                            {this.state.myEarnings.total_amount ? this.state.myEarnings.monthly_week_wise.totalearnings.map(
                                                (weeklyEarning: any, i: any) => {
                                                    return (
                                                        <Box p={1} key={i}>
                                                            <Tooltip
                                                                title={weeklyEarning.value}
                                                                placement="top"
                                                            >
                                                                <div
                                                                    style={{
                                                                        width: "2rem",
                                                                        height: `${(weeklyEarning.value / this.state.myEarnings.total_amount) * 100}px`,
                                                                        backgroundColor: "blue",
                                                                        borderRadius: "0.2rem",
                                                                    }}
                                                                />
                                                            </Tooltip>
                                                            <Typography variant="body2">
                                                                W{i + 1}
                                                            </Typography>
                                                        </Box>
                                                    );
                                                }
                                            )
                                                : null}
                                        </Box>
                                        <Typography variant="subtitle2" color="textPrimary">
                                            Earning this Month
                                        </Typography>
                                        <Typography variant="h3" color="primary">
                                            {this.state.myEarnings.total_amount}$
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {this.state.myEarnings.percent_difference} % better
                                            than <br /> Previos Month
                                        </Typography>
                                    </Box>
                                </Paper>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        );
    }
}

// Customizable Area Start

const MyEarningsAlertBox = withAlertBox(MyEarnings);
const MyEarningsLoader = withLoader(MyEarningsAlertBox);
const MyEarningsToast = withToast(MyEarningsLoader);
// const MyEarningsWithDialog = withDialog(MyEarningsToast);
const MyEarningsTheme = withTheme(MyEarningsToast);

// Customizable Area End
export default withStyles((theme) =>
    createStyles({
        root: {},
        uploadContainer: {
            borderRadius: "1rem",
            backgroundColor: "#f1f1f1",
            textAlign: "center",
            padding: "2rem",
            marginRight: "2rem",
            marginTop: "1rem",
        },
        StatisticsContainer: {
            borderRadius: "1rem",
            marginTop: "1rem",
        },
        StatisticsItems: {
            border: "1px solid #f1f1f1",
            padding: "1.6rem",
        },
        earningContainer: {
            borderRadius: "1rem",
            marginTop: "1rem",
        },
        earningItems: {
            border: "1px solid #f1f1f1",
            padding: "1.6rem",
        },
        earningTitle: {
            margin: "1rem",
            padding: "1rem",
            backgroundColor: "#f1f1f1",
            borderRadius: "0.5rem",
        },
        month: {
            backgroundColor: "#F7F7F7",
            color: "blue",
            borderRadius: "1rem",
            padding: "12px 15px",
            minWidth: "200px",
            textAlign: "center",
            "& .MuiTypography-subtitle1": {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#3aaeef",
            },
        },
        topViewEarning: {
            margin: "0 20px",
            backgroundColor: "white",
            borderRadius: "15px",
            padding: "25px 15px 50px",
            marginTop: "25px",
        },
        myEarningsView: {
            backgroundColor: "#F0F0F0",
            borderRadius: "1rem",
            padding: "1rem",
            width: "100%",
            marginTop: "-10px",
        },
        darktopView: {
            backgroundColor: "#424242",
            "& h3" : {
                color: "#3aaeef",
            },
        },
        darkMonth: {
            backgroundColor: "#676767",
        }
    })
)(MyEarningsTheme);
