Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "QrCodes";
exports.labelBodyText = "QrCodes Body";
exports.btnExampleTitle = "CLICK ME";

// Customizable Area End


// /** Methods List /
exports.apiGetMethod = "GET";
exports.apiPostMethod = "POST";
exports.apiPutMethod = "PUT";
exports.apiDeleteMethod = "DELETE";
exports.apiPatchMethod = "PATCH";

// /** API Constants /
exports.ApiContentType = "application/json";
exports.EditApiContentType = "multipart/form-data";

// /** API's for Business Dashbpard /
exports.getUserApiEndPoint= "account_block/accounts/2/get_user_profile";
// Customizable Area End