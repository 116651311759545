import React, { useEffect, useState } from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End
import { withRouter, Link } from "react-router-dom";
import "../../../blocks/catalogue/assets/css/home.css"
import "../../../blocks/catalogue/assets/css/common.css"
import withTheme from "../Theme/withTheme";
import Grid from "@material-ui/core/Grid";

const styles = {
    searchIcon: {
        height: "100%",
        position: "absolute",
        pointerEvents: "none",
        display: "flex",
        left: "3%",
        alignItems: "center",
        justifyContent: "center",
    },
    search: {
        position: "relative",
        background: "#5196DD",
        color: "white",
        width: "100%",
        borderRadius: "8px",
        marginTop: "2%",
    },
    inputRoot: {
        color: "inherit",
    },
    inputInput: {
        width: "100%",
        padding: "13px 50px",
    },
    cardContainer: {
        height: "auto",
        backgroundColor: "white",
        width: "auto",
        margin: "10px",
        borderRadius: "8px",
    },
    formControl: {
        minWidth: 120,
    },
} as any;

const BookListing = (props: any) => {
    const { classes } = props;
    const location = window.location.pathname.split('/')[2];
    const [symbol, setSymbol] = useState("");

    const handleBookfilter = props?.allBooks && props?.allBooks.filter((item: any) => {
        return item.attributes.is_purchased === false
    })
    console.log("@@@=@==",handleBookfilter)

    useEffect(() => {
        let localData = localStorage.getItem("symbol");
        //@ts-ignore
        setSymbol(localData);
    }, [])
console.log("abc",)
    return (
        <>
            <div className={`book-items-main ${location == "BookDetails" && "similarbook-page"}`}>
                {location == "BookDetails" && <h1>Similar Books</h1>}
                <div className="store-items-block ">
                    <Grid container spacing={4}>
                        {props?.allBooks && handleBookfilter.map((x: any, index: any) => {
                            return (
                                <Grid item xs={6} md={3} sm={3} lg={2}>
                                    <div className="image-cell" key={index}>
                                        {window.location.pathname == `/Catalogue/Home` && <div className={`price ${props.mode && "dark1"}`}>{symbol}{x.attributes.full_price}</div>}
                                        <div className="image-block">
                                            {window.location.pathname != (`/Catalogue/Home`)
                                                && window.location.pathname.split("/")[1] != (`package-books`)
                                                && window.location.pathname != (`/view-allBooks`)
                                                && <img className="playbutton click-pointer"
                                                    src={require("./images/Play Button.png")}
                                                    onClick={() => {
                                                        //@ts-ignore
                                                        props.history.replace(`/book-read/${x.attributes.id}`);
                                                    }}
                                                />}
                                            <img className="skeleton-loader-background" src={x.attributes.cover_image_url} onClick={() => {
                                                // const { history }: any = props;
                                                props.history.push(`/Catalogue/BookDetails/${x.attributes.id}`);
                                                localStorage.setItem("boookid",x.attributes.id)
                                            }} />
                                            {window.location.pathname == `/Catalogue/Home` &&
                                                <img
                                                    className="play_btn click-pointer"
                                                    src={require("./images/Play Button.png")}
                                                    onClick={() => {
                                                        //@ts-ignore
                                                        props.history.replace(`/book-read/${x.attributes.id}`);
                                                    }} />
                                            }
                                        </div>
                                        <p className={`image-name ${props.mode && "darkFont"}`}>{x.attributes.name.slice(0, 26)}</p>
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>

                {window.location.pathname != (`/Catalogue/Home`) && window.location.pathname.split("/")[1] != (`package-books`) && props.allBooks && (
                    <div className={`view-books ${location == "BookDetails" && "view-books-similar"} ${props.mode && "dark-boxshadow"}`}
                        onClick={() => { props.history.push('/view-allBooks') }}>
                        <span>
                            View all books
                        </span>
                        <button className="btn-next" />
                    </div>
                )}
            </div>
        </>
    );
};

const BookListingRouter = withRouter(BookListing);
const BookListingTheme = withTheme(BookListingRouter);
export default withStyles(styles)(BookListingTheme);
